<template>
    <div style="height: 100%; background-color: #f6f7fb;">
        <b-container fluid style="height: 100%;">
            <b-row>
                <b-col style="padding-left: 0; padding-right: 0;">
                    <b-navbar type="light" variant="light" style="border-bottom: lightgrey solid 1px;">
                        <b-navbar-brand>
                            <router-link to="/"><img src="@/assets/images/branding/brandingBlue.png" height=25px style="margin-bottom: 5px;"></router-link>
                        </b-navbar-brand>
                    </b-navbar>
                </b-col>
            </b-row>
            <b-row style="padding-left: 0; padding-right: 0; height: calc(100% - 59px);">
                <b-col cols="12">
                    <b-container style="margin-top: 50px; border-radius: 4px; border: 1px solid lightgray; min-height: 500px; padding: 50px; font-family: Roboto; color: rgb(73, 80, 87); background-color: white;">
                        <b-row>
                            <b-col cols="2"></b-col>
                            <b-col cols="10">
                                <h1>Login</h1>
                                <b-row>
                                    <b-col cols="6" style="min-width: 500px;">
                                        <b-form-group label="Email" label-for="loginEmail">
                                            <b-form-input required type="email" id="loginEmail" placeholder="Enter email" v-model="email" tabindex="1">
                                            </b-form-input>
                                        </b-form-group>
                                        <b-form-group label="Password" label-for="loginPassword">
                                            <b-form-input required type="password" id="loginPassword" placeholder="Enter password" v-model="password" tabindex="2">
                                            </b-form-input>
                                        </b-form-group>
                                        <!--<vue-hcaptcha sitekey="1b99a4b3-b5e2-4ef6-ade9-2ec2ddd12bcc" @verify="onVerify" @expired="onExpired" @error="onError" required  tabindex="7"></vue-hcaptcha>-->
                                        <b-button type="submit" variant="success" style="min-width: 125px;" @click="login" tabindex="3">Login</b-button>
                                        <b-link style=" margin-left: 15px;"><strong>Forgot password?</strong></b-link>
                                    </b-col>
                                    <!--<b-col cols="3" style="padding-top: 8px; opacity: 0.5;">
                                        <span style="height: 24px;"></span><br>
                                        <b-button style="width: 200px;" variant="google" tabindex="4" v-b-tooltip.hover title="This feature is currently disabled!"><i class="fab fa-google"></i> Login with Google</b-button><br>
                                        <b-button style="width: 200px; margin-top: 15px;" variant="discord" tabindex="5" v-b-tooltip.hover title="This feature is currently disabled!"><i class="fab fa-discord"></i> Login with Discord</b-button><br>
                                        <b-button style="width: 200px; margin-top: 15px;" variant="github" tabindex="6" v-b-tooltip.hover title="This feature is currently disabled!"><i class="fab fa-github"></i> Login with GitHub</b-button><br>
                                    </b-col>-->
                                    <b-col cols="3" style="min-width: 200px;">
                                        <span v-if="qrcode == null">
                                            <VueSkeletonLoader
                                                type="rect"
                                                :width="200"
                                                :height="200"
                                                animation="fade"
                                            />
                                        </span>
                                        <span>
                                            <img v-bind:src="'https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=' + qrcode">
                                            <p>
                                                Scan this with the <b>Stacket mobile app</b> to login
                                            </p>
                                        </span>
                                    </b-col>
                                </b-row>
                                <b-row style="margin-top: 50px;">
                                    <b-col>
                                        <span>Don't have an account?</span><b-link style=" margin-left: 5px;" to="/register"><strong>Register</strong></b-link>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="2"></b-col>
                        </b-row>
                    </b-container>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
    name: "Login",
    components: {
       // VueHcaptcha
      // Skeleton,
       VueSkeletonLoader
    },
    data: () => {
        return {
            email: "",
            password: "",
            recaptchaSecret: "",
            qrcode: null,
        }
    },
    mounted() {
        if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
            //verify the token.. :)
            this.$http.post(this.StacketConfig.api.auth + "/verify", {"token": token}).then(response => {
                if(response.body.state == "verified"){
                    this.$router.push("/dashboard");
                } else {
                    localStorage.removeItem("user-token");
                }
            }).catch(err => {
                console.log(err);
            })
        };
        this.$http.get(this.StacketConfig.api.auth + "/qrcode").then(response => {
            this.qrcode = response.body._id;
        }).catch(e => {
            console.error(e);
        })
        var Vue = this;
        var checkQR = setInterval(function() {
            Vue.seconds = "Checking.."
            Vue.$http.get(Vue.StacketConfig.api.auth + "/qrcode/" + Vue.qrcode).then(response => {
                if(response.body.token){
                    localStorage.setItem('user-token', response.body.token);
                    Vue.$router.push("/dashboard");
                }
            }).catch(e => {
                console.error(e);
            })
        }, 5000);
    },
    methods: {
        login: function(){
            if(this.email == ""){
                return this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Error",
                    text: "It looks like you haven't entered a email!"
                })
            }
            if(this.password == ""){
                return this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Error",
                    text: "It looks like you haven't entered a password!"
                })
            }
            /*if(this.recaptchaSecret == ""){
                return this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Error",
                    text: "Whoups, it looks like you haven't solved hCaptcha yet!"
                })
            }*/
            this.$http.post(this.StacketConfig.api.auth + "/login", {"email": this.email, "password": this.password}).then(response => {
                if(response.body.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "Error",
                        text: response.body.error
                    })
                }
                if(response.body.type == "email"){
                    return this.$notify({
                        group: "notifications",
                        type: 'success',
                        title: "Verify",
                        text: "Accept the verification email to get access!"
                    })
                }
                //localStorage.setItem('user-token', response.body.token);

                if(response.body.u2f){
                    const token = response.body.token;
                    if(window.u2f && window.u2f.sign){
                        this.$notify({
                            group: "notifications",
                            type: 'success',
                            title: "U2F Authentication",
                            text: "Click on your U2F Authention harware device to proceed"
                        })
                        window.u2f.sign(response.data.u2f.appId, response.data.u2f.challenge, [response.data.u2f], result => {
                            this.$http.post(this.StacketConfig.api.auth + "/u2f", { authResponse: result }, {headers: { authorization: token}}).then(response2 => {
                                if(response2.body.error){
                                    return this.$notify({
                                        group: "notifications",
                                        type: 'error',
                                        title: "Error",
                                        text: response2.data.error
                                    })
                                }
                                localStorage.setItem('user-token', token);
                                this.$router.push("/dashboard");
                            }).catch(e => {
                                console.error(e);
                                if(e.data && e.data.error) return this.$notify({
                                    group: "notifications",
                                    type: 'error',
                                    title: "Error",
                                    text: e.data.error
                                })
                                return this.$notify({
                                    group: "notifications",
                                    type: 'error',
                                    title: "Error",
                                    text: "Something went wrong!"
                                })
                            })
                        });
                    } else {
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "Error",
                            text: "Your browser does not support U2F Authentication"
                        })
                    }
                } else {
                    localStorage.setItem('user-token', response.body.token);
                    this.$router.push("/dashboard");
                }
            }).catch(err => {
                console.log(err)
                return this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Error",
                    text: "An error accured while trying to login."
                })
            })
        },
        onVerify: function (response) {
            this.recaptchaSecret = response;
        },
        onExpired: function () {
            this.recaptchaSecret = "";
        },
        onError: function () {
            this.recaptchaSecret = "";
            return this.$notify({
                group: "notifications",
                type: 'error',
                title: "HCaptcha Error",
                text: "Something went wrong!"
            })
        }
    }
}
</script>

<style lang="scss">


</style>