<template>
    <b-card-group deck v-if="service.team">
        <b-card body-tag="div" header-bg-variant="white" header-tag="header" style="border-color: #eff0f5;" class="shadow-xl serviceCard">
            <template v-slot:header>
                <router-link :to="'/dashboard/'+service._id+'/overview'" style="text-decoration: none;">
                    <h6 class="mb-0" style="color: #777676;">
                        <span style="color: #777676;">{{ service.team.name }} - {{ service.name }}</span>
                        <i class="fas fa-circle" style="color:#2ead42!important; float: right;" v-if="service.status == 'online'"></i>
                        <i class="fas fa-circle" style="color:#f13862!important; float: right;" v-else-if="service.status == 'offline'"></i>
                        <i class="fas fa-circle" style="color:#c9c8c8!important; float: right;" v-else-if="service.status == 'hibernating'"></i>
                    </h6>
                </router-link>
            </template>
            <b-card-body v-if="service.team.groups && service.team.groups.length != 0">
                <b-row style="margin-bottom: 5px;">
                    <b-col cols="4">
                        <center>
                            <img v-bind:src="service.team.image" style="width: 50px;" v-b-tooltip.hover v-bind:title="service.team.name">
                        </center>
                    </b-col>
                    <b-col cols="1">
                        <center style="height: 100%;">
                            <div style="height: 100%; border: #e2e2e4 solid 0.5px;"></div>
                        </center>
                    </b-col>
                    <b-col style="padding-top: 10px;">
                        <span v-html="convertName(service.team.groups[0].name || '')" v-if="service.team.groups[0]" v-b-tooltip.hover v-bind:title="service.team.groups[0].name" class="align-middle shadow-sm" style="text-transform: uppercase; text-align: center; background-color: #eaf8eb; font-weight: 500; font-size: 12px; color: #2ead42; padding: 7px; padding-top: 10px; border-radius: 100%; width: 35px; height: 35px;">
                            
                        </span>
                        <span v-html="convertName(service.team.groups[1].name || '')" v-if="service.team.groups[1]" v-b-tooltip.hover v-bind:title="service.team.groups[1].name" class="align-middle shadow-sm" style="text-transform: uppercase; text-align center; background-color: #f0f3fa; font-weight: 500; font-size: 12px; color: #477ed9; padding: 7px; padding-top: 10px; border-radius: 100%; width: 35px; height: 35px; margin-left: 10px;">
                            
                        </span>
                        <span v-html="'+'+(service.team.groups.length - 2)" v-if="service.team.groups[2]" class="align-middle shadow-sm" style="text-transform: uppercase; text-align: center; background-color: #ffeff1; font-weight: 500; font-size: 13px; color: #f13862; padding: 9px; padding-top: 9px; border-radius: 100%; width: 35px; height: 35px; margin-left: 10px;">
                            
                        </span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4">
                        <center>
                            <h6>
                                Team
                            </h6>
                        </center>
                    </b-col>
                    <b-col>
                        <center>
                            <h6>
                                Groups
                            </h6>
                        </center>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body v-else="">
                <b-row style="margin-bottom: 5px;">
                    <b-col>
                        <center>
                            <img v-bind:src="service.team.image" style="width: 50px;" v-b-tooltip.hover v-bind:title="service.team.name">
                        </center>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <center>
                            <h6>
                                Team
                            </h6>
                        </center>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                <b-row>
                    <b-col style="margin-left: -15px;">
                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                            {{ service.type }} Hosting
                        </h6>
                    </b-col>
                    <b-col>
                        <h6 style="text-align: right; font-size: 13px" v-if="pricing">
                            ${{ pricing[service.package].price.toFixed(2) }}
                            <span style="font-size:10px;">
                                /mo
                            </span>
                        </h6>
                        <h6 style="text-align: right; font-size: 13px" v-else="">
                            Loading prices
                        </h6>
                    </b-col>
                </b-row>
            </b-card-footer>
            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                <b-row>
                    <b-col style="margin-left: -15px;">
                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                            Disk Path
                        </h6>
                    </b-col>
                    <b-col>
                        <h6 style="text-align: right; font-size: 13px;" >
                            {{ service.disks.find(net => net.id == disk.disk._id).path.disk }}
                        </h6>
                    </b-col>
                </b-row>
            </b-card-footer>
            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                <b-row>
                    <b-col style="margin-left: -15px;">
                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                            Service Path
                        </h6>
                    </b-col>
                    <b-col>
                        <h6 style="text-align: right; font-size: 13px;" >
                            {{ service.disks.find(net => net.id == disk.disk._id).path.service }}
                        </h6>
                    </b-col>
                </b-row>
            </b-card-footer>
            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -20px;">
                <b-row>
                    <b-col style="text-align: center;" @click="disconnect(disk.disk._id, service._id)">
                        <i v-b-tooltip.hover title="Disconnect" class="fas fa-unlink trashIcon" style="color:lightgray;"></i>
                    </b-col>>
                </b-row>
            </b-card-footer>
        </b-card>
    </b-card-group>
    <b-card-group deck v-else="">
        <b-card body-tag="div" header-bg-variant="white" header-tag="header" style="border-color: #eff0f5;" class="shadow-xl serviceCard">
            <template v-slot:header>
                <router-link :to="'/dashboard/'+service._id+'/overview'" style="text-decoration: none;">
                    <h6 class="mb-0" style="color: #777676;">
                        <span style="color: #777676;">{{ service.name }}</span>
                        <i class="fas fa-circle" style="color:#2ead42!important; float: right;" v-if="service.status == 'online'"></i>
                        <i class="fas fa-circle" style="color:#f13862!important; float: right;" v-else-if="service.status == 'offline'"></i>
                        <i class="fas fa-circle" style="color:#c9c8c8!important; float: right;" v-else-if="service.status == 'hibernating'"></i>
                    </h6>
                </router-link>
            </template>
            <b-card-body v-if="service.subusers && service.subusers.length != 0">
                <b-row style="margin-bottom: 5px;">
                    <b-col cols="4">
                        <center>
                            <img v-bind:src="service.owner.image" style="width: 50px; border-radius: 100%;" v-b-tooltip.hover v-bind:title="service.owner.fullname">
                        </center>
                    </b-col>
                    <b-col cols="1">
                        <center style="height: 100%;">
                            <div style="height: 100%; border: #e2e2e4 solid 0.5px;"></div>
                        </center>
                    </b-col>
                    <b-col style="padding-top: 10px;">
                        <span v-html="convertName(service.subusers[0].fullname || '')" v-if="service.subusers[0]" v-b-tooltip.hover v-bind:title="service.subusers[0].fullname" class="align-middle shadow-sm" style="text-transform: uppercase; text-align: center; background-color: #eaf8eb; font-weight: 500; font-size: 12px; color: #2ead42; padding: 7px; padding-top: 10px; border-radius: 100%; width: 35px; height: 35px;">
                            
                        </span>
                        <span v-html="convertName(service.subusers[1].fullname || '')" v-if="service.subusers[1]" v-b-tooltip.hover v-bind:title="service.subusers[1].fullname" class="align-middle shadow-sm" style="text-transform: uppercase; text-align: center; background-color: #f0f3fa; font-weight: 500; font-size: 12px; color: #477ed9; padding: 7px; padding-top: 10px; border-radius: 100%; width: 35px; height: 35px; margin-left: 10px;">
                            
                        </span>
                        <span v-html="'+'+(service.subusers.length - 2)" v-if="service.subusers[2]" class="align-middle shadow-sm" style="text-transform: uppercase; text-align: center; background-color: #ffeff1; font-weight: 500; font-size: 13px; color: #f13862; padding: 9px; padding-top: 9px; border-radius: 100%; width: 35px; height: 35px; margin-left: 10px;">
                            
                        </span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4">
                        <center>
                            <h6>
                                Owner
                            </h6>
                        </center>
                    </b-col>
                    <b-col>
                        <center>
                            <h6>
                                Shared People
                            </h6>
                        </center>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body v-else="">
                <b-row style="margin-bottom: 15px;">
                    <b-col>
                        <center>
                            <img v-bind:src="service.owner.image" style="width: 50px; border-radius: 100%;" v-b-tooltip.hover v-bind:title="service.owner.fullname">
                        </center>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <center>
                            <h6>
                                Owner
                            </h6>
                        </center>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                <b-row>
                    <b-col style="margin-left: -15px;">
                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                            {{ service.type }} Hosting
                        </h6>
                    </b-col>
                    <b-col>
                        <h6 style="text-align: right; font-size: 13px" v-if="pricing">
                            ${{ pricing[service.package].price.toFixed(2) }}
                            <span style="font-size:10px;">
                                /mo
                            </span>
                        </h6>
                        <h6 style="text-align: right; font-size: 13px" v-else="">
                            Loading prices
                        </h6>
                    </b-col>
                </b-row>
            </b-card-footer>
            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                <b-row>
                    <b-col style="margin-left: -15px;">
                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                            Disk Path
                        </h6>
                    </b-col>
                    <b-col>
                        <h6 style="text-align: right; font-size: 13px;" >
                            {{ service.disks.find(net => net.id == disk.disk._id).path.disk }}
                        </h6>
                    </b-col>
                </b-row>
            </b-card-footer>
            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                <b-row>
                    <b-col style="margin-left: -15px;">
                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                            Service Path
                        </h6>
                    </b-col>
                    <b-col>
                        <h6 style="text-align: right; font-size: 13px;" >
                            {{ service.disks.find(net => net.id == disk.disk._id).path.service }}
                        </h6>
                    </b-col>
                </b-row>
            </b-card-footer>
            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -20px;">
                <b-row>
                    <b-col style="text-align: center;" @click="disconnect(disk.disk._id, service._id)">
                        <i v-b-tooltip.hover title="Disconnect" class="fas fa-unlink trashIcon" style="color:lightgray;"></i>
                    </b-col>
                </b-row>
            </b-card-footer>
        </b-card>
    </b-card-group>
</template>

<style lang="scss">
    .copyLink {
        float:right;
        cursor: pointer;
        color: #c9c8c8!important;
        transition: 0.15s;
    }
    .copyLink:hover {
        color: #9c9c9c!important;
    }
    .productIcons {
        cursor: pointer;
        color: #c9c8c8!important;
        transition: 0.15s;
    }
    .productIcons:hover {
        color: #6b6b6b!important;
    }
    .serviceCard{
        transition: all .2s ease-in-out;
    }
    .serviceCard:hover {
        transform: scale(1.025);
    }
</style>

<script>

export default {
    name: "diskserviceCard",
    components: {
        
    },
    data: () => {
        return {
            token: "",
            pricing: {},
        }
    },
    props: ['service', 'disk'],
    mounted() {
        this.token = localStorage.getItem("user-token");
        if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
        }
        this.getPricingService();
    },
    methods: {
        async getPricingService() {
            var pricing = await this.$http.get(this.StacketConfig.api.services + `/${this.service._id}/packages`, {headers: {"authorization": this.token}});
            this.pricing = pricing.data;
        },
        convertName(name) {
            var fullname = name+"";
            var split = fullname.split(" ");
            if(split.length == 1){
                return split[0].charAt(0) + split[0].charAt(1);
            } else {
                var firstName = split[0];
                var lastName = split[split.length-1];

                return firstName.charAt(0) + lastName.charAt(0);
            }

        },
        connect(disk, service) {
            console.log("Connecting " + service + ", please wait...");
            this.$http.get(this.StacketConfig.api.disks + `/${disk}/connect/${service}`, {headers: {"authorization": this.token}}).then(response => {
                console.log(response.data);
                this.$parent.getdisk();
                this.$parent.$parent.$parent.getServices();
            }).catch(err => {
                console.log(err);
            })
        },
        disconnect(disk, service) {
            console.log("Disconnecting " + service + ", please wait...");
            this.$http.get(this.StacketConfig.api.disks + `/${disk}/disconnect/${service}`, {headers: {"authorization": this.token}}).then(response => {
                console.log(response.data);
                this.$parent.getdisk();
                this.$parent.$parent.$parent.getServices();
            }).catch(err => {
                if(err.data.error) {
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: err.data.error
                    });
                }
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not disconnect service!"
                });
            })
        }
    }
}  

</script>