<template>
    <b-container fluid>
        <b-row align-h="end">
            <b-col v-if="service.service.type == 'csgo'">
                <b-input-group>
                    <b-button variant="primary" @click="updateService(false)" style="margin-right: 25px;">Update</b-button>
                    <b-button variant="primary" @click="updateService(true)">Update and Validate</b-button>
                </b-input-group>
            </b-col>
            <b-col v-if="service.service.type == 'nodejs'">
                <b-input-group>
                    <b-button variant="primary" @click="npminstallService('')" style="margin-right: 25px;">NPM Install</b-button>
                </b-input-group>
            </b-col>
            <b-col class="ml-5">
                <i class="fas fa-circle" style="color:#2ead42!important;" v-if="service.status == 'online'"></i>
                <i class="fas fa-circle" style="color:#f13862!important;" v-else-if="service.status == 'offline'"></i>
                <i class="fas fa-circle" v-else-if="service.status == 'hibernating'"></i>
                <strong class="ml-3" style="color:#2ead42!important;" v-if="service.status == 'online'">Online</strong>
                <strong class="ml-3" style="color:#f13862!important;" v-else-if="service.status == 'offline'">Offline</strong>
                <strong class="ml-3" v-else-if="service.status == 'hibernating'">Hibernating</strong>
            </b-col>
            <b-button class="mr-2" variant="success" v-on:click="$parent.startService() && setTimeout(this.$parent.getActions(), 1000)">Start</b-button>
            <b-button class="mr-2" variant="danger" v-on:click="$parent.stopService() && setTimeout(this.$parent.getActions(), 1000)">Stop</b-button>
            <b-button class="mr-2" variant="danger" v-on:click="$parent.killService() && setTimeout(this.$parent.getActions(), 1000)">Kill</b-button>
            <b-button class="mr-2" variant="primary" v-on:click="$parent.hibernateService() && setTimeout(this.$parent.getActions(), 1000)">Hibernate</b-button>
            <b-button class="mr-5" variant="warning" v-on:click="$parent.reactivateService() && setTimeout(this.$parent.getActions(), 1000)">Reactivate</b-button>
        </b-row>
        <hr>
        <b-row>
            <b-col>
                <log-viewer :log="log" :loading="isLoading" />
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col>
                <p>
                    Execute Command
                </p>
                <b-input-group prepend="/" class="mt-3">
                    <b-form-input v-model="command"></b-form-input>
                    <b-input-group-append>
                        <b-button variant="primary" @click="executeCommand">Execute</b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
 
</script>
<style lang="scss">
    
</style>
<script>

const io = require("socket.io-client")

import logViewer from "@femessage/log-viewer"; 

export default {
    name: "Console",
    components: {
        logViewer
    },
    data: () => {
        return {
            token: "",
            service: {},
            command: "",
            socket: null,
            isLoading: false,
            log: ""
        }
    },
    mounted() {
        this.token = localStorage.getItem("user-token");
        this.service = this.$parent.service;

        this.socket = io(`https://console_devapi.stacket.net:2083/service?serviceId=${this.$route.params.page}&authorization=${this.token}`, {
            "transports": ["websocket"]
        });

        this.socket.connect();

        //socket io events
        this.log += "\n" + "Connecting to console API..."
        var Vue = this;
        this.socket.on('connect', function(){
            console.log("Connected to console api")
            Vue.log += "\n" + "Connected to console API"
        });
        this.socket.on('console', function(data) {
            console.log("Received console")
            console.log(data);
            Vue.log += "\n" + data
        });
        this.socket.on('disconnect', function(){
            console.log("Disconnected from console api")
            Vue.log += "\n" + "Disconnected from console API!"
        });
    },
    beforeDestroy(){
        if(this.socket != null) this.socket.disconnect();
    },
    methods: {
        npminstallService(packages){
            this.$http.post(this.StacketConfig.api.services + "/" + this.$route.params.page + "/control", {"state": "npmInstall", "packages": packages}, {headers: {"authorization": this.token}}).then(async response => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Installing packages",
                    text: "Please wait.."
                })
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not install packages!"
                })
            });
        },
        updateService(validate){
            this.$http.post(this.StacketConfig.api.services + "/" + this.$route.params.page + "/control", {state: "update", validate: validate}, {headers: {"authorization": this.token}}).then(async response => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Updating Service",
                    text: "Please wait.."
                })
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not update service!"
                })
            });
        },
        executeCommand(){
            this.$http.post(this.StacketConfig.api.services + "/" + this.$route.params.page + "/control", {state: "console", command: this.command}, {headers: {"authorization": this.token}}).then(async response => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                this.command = "";
                this.$notify({
                    group: "notifications",
                    type: 'warning',
                    title: "Executing command",
                    text: "Please wait"
                });
                var Vue = this;
                var interval = setInterval(async function() {
                    Vue.$http.get(Vue.StacketConfig.api.services + "/" + Vue.$route.params.page + "/actions/" + response.data._id, {headers: {"authorization": Vue.token}}).then(async (status) => {
                        if(status.data.error){
                            window.clearInterval(interval);        
                            return Vue.$notify({
                                group: "notifications",
                                type: 'error',
                                title: "API ERROR",
                                text: status.data.error
                            })
                        }
                        if(status.data.status == "success"){  
                            window.clearInterval(interval);                   
                            return Vue.$notify({
                                group: "notifications",
                                type: 'success',
                                title: "Success!",
                                text: "Command has been executed"
                            });
                        } else if(status.data.status == "error"){
                            window.clearInterval(interval);                   
                            return Vue.$notify({
                                group: "notifications",
                                type: 'error',
                                title: "Error!",
                                text: status.data.error.message
                            })
                        }
                    }).catch(err => {
                        Vue.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: "Could not fetch action!"
                        })
                    });
                }, 1500);
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not execute command!"
                })
            });
        },
    }
}  
</script>