<template>
    <b-container fluid v-if="!this.$route.params.page2">
        <b-row class="mt-4">
            <b-col>
                <b-button variant="primary" v-b-modal.newDisk><i class="far fa-hdd"></i> Order Disk</b-button>

                <b-modal id="newDisk" title="Order virtual disk" ok-only centered @ok="createDisk" v-bind:okTitle="'Order ($' + getPrice(newDisk.size).toFixed(2) +')'">
                    <b-form-group
                        label="Disk Name"
                    >
                        <b-form-input
                        id="diskName"
                        v-model="newDisk.name"
                        required
                        placeholder="Enter name"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        v-bind:label="'Size: ' + newDisk.size + 'gb'"
                    >
                        <b-form-input id="range-2" v-model="newDisk.size" type="range" min="5" max="1000" step="5"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Ownership">
                        <b-form-radio v-model="newDisk.ownership" name="ownership-radios" value="personal">Personal</b-form-radio>
                        <b-form-radio v-model="newDisk.ownership" name="ownership-radios" value="team">Team</b-form-radio>
                    </b-form-group>
                    <b-form-group
                        label="Team"
                        v-if="newDisk.ownership == 'team'"
                    >
                        <b-form-select style="" v-model="newDisk.team" id="networkTeam">
                            <template v-slot:first>
                                <b-form-select-option :value="null" disabled>Select a Team</b-form-select-option>
                            </template>
                            <b-form-select-option v-for="team in teams" v-bind:key="team._id" :value="team._id">
                                {{ team.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                    
                </b-modal>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="3" v-for="(disk, index) of disks" v-bind:key="index" style="min-width: 375px; margin-bottom: 25px;">
                <diskCard :disk="disk" :key="index"></diskCard>
            </b-col>
        </b-row>
        <!-- <span v-for="(network, index) of networks" v-bind:key="index">
            <b-row style="margin-top: 50px;">
                <b-col cols="3" style="min-width: 375px; margin-bottom: 25px;">
                    <h1>{{ network.name }}</h1>
                    <p>{{ network._id }}</p>
                </b-col>
                <b-col>
                    <b-form-select style="width: 80%; margin-top: 5px; font-size: 17px; font-family: 'Roboto', sans-serif; font-weight: 500;" v-model="connectId">
                        <template v-slot:first>
                            <b-form-select-option :value="null" disabled>Select a Service</b-form-select-option>
                        </template>
                        <b-form-select-option v-for="service in services" v-bind:key="service" :value="service._id">
                            {{ service.name }}
                        </b-form-select-option>
                    </b-form-select>
                    <b-button variant="success" @click="connect(network._id, connectId)">Connect</b-button>
                </b-col>
            </b-row>
            <b-row style="margin-top: 15px;">
                <b-col cols="3" style="min-width: 375px; margin-bottom: 25px;" v-for="(service, index) of network.services" v-bind:key="index">
                    <p>
                        <b>{{ service.name }}</b>
                        <br>
                        <b-button variant="danger" @click="disconnect(network._id, service._id)">Disconnect</b-button>
                    </p>
                    <p>
                        {{ service._id }}
                    </p>
                    <p>
                        {{ (service.networks.find(net => net.id == network._id).ipv4 ? 'IPv4' : (service.networks.find(net => net.id == network._id).ipv6) ? 'IPv6' : "Unknown") }}: {{ service.networks.find(net => net.id == network._id).ipv4 || service.networks.find(net => net.id == network._id).ipv6 }}
                    </p>
                </b-col>
            </b-row>
            <b-row style="margin-top: 20px;">
                <b-col>
                    <hr>
                </b-col>
            </b-row>
        </span> -->
    </b-container>
    <disk v-else :teams="teams" :disks="disks" :services="services"></disk>
</template>

<style>
    .copyLink {
        float:right;
        cursor: pointer;
        color: #c9c8c8!important;
        transition: 0.15s;
    }
    .copyLink:hover {
        color: #9c9c9c!important;
    }
    .productIcons {
        cursor: pointer;
        color: #c9c8c8!important;
        transition: 0.15s;
    }
    .productIcons:hover {
        color: #6b6b6b!important;
    }
    .serviceCard{
        transition: all .2s ease-in-out;
    }
    .serviceCard:hover {
        transform: scale(1.025);
    }
</style>

<script>
import diskCard from '@/components/diskCard.vue';
import disk from '@/pages/dashboard/disk.vue';

export default {
    name: "Disks",
    props: [
        "teams",
        "disks",
        "services"
    ],
    components: {
        diskCard,
        disk
    },
    data: () => {
        return {
            token: "",
            connectId: "",
            newDisk: {
                name: "",
                size: 5,
                team: null,
                ownership: 'personal'
            }
        }
    },
    mounted() {
        this.token = localStorage.getItem("user-token");
        /*if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
            //verify the token.. :)
            this.$http.post(this.StacketConfig.api.auth + "/verify", {"token": token}).then(response => {
                if(response.body.state == "verified"){
                    //
                    //this.getDisks();
                } else {
                    localStorage.removeItem("user-token");
                    return this.$router.push("/login");
                }
            }).catch(err => {
                console.log(err);
            })
        }*/
    },
    methods: {
        getPrice(gb){
            var price = {
                "20": 0.20,
                "50": 0.15,
                "100": 0.12,
                "250": 0.10,
                "500": 0.08,
                "normal": 0.06
            }
            if(gb <= 20) return gb * price["20"];
            if(gb <= 50) return gb * price["50"];
            if(gb <= 100) return gb * price["100"];
            if(gb <= 250) return gb * price["250"];
            if(gb <= 500) return gb * price["500"];
            return gb * price["normal"];
        },
        getDisks(){
            this.$http.get(this.StacketConfig.api.disks + `/`, {headers: {"authorization": this.token}}).then(response => {
                this.disks = response.data;
            }).catch(err => {
                console.log(err);
            })
        },
        createDisk() {
            this.$notify({
                group: "notifications",
                type: 'warning',
                title: "Creating..",
                text: "Please wait."
            });
            this.$http.post(this.StacketConfig.api.disks + `/`, this.newDisk, {headers: {"authorization": this.token}}).then(response => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Success!",
                    text: "Virtual Disk created"
                });
                this.$parent.getDisks();
                this.addDomain = {
                    domain: null,
                    team: null,
                    ownership: 'personal'
                }
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not create Virtual Disk"
                })
            });
        }
    }
}  
</script>