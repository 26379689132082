<template>
    <div style="height: 100%; background-color: #f6f7fb;">
        <b-container fluid style="height: 100%;">
            <b-row>
                <b-col style="padding-left: 0; padding-right: 0;">
                    <b-navbar type="light" variant="light" style="border-bottom: lightgrey solid 1px;">
                        <b-navbar-brand>
                            <router-link to="/"><img src="@/assets/images/branding/brandingBlue.png" height=25px style="margin-bottom: 5px;"></router-link>
                        </b-navbar-brand>
                    </b-navbar>
                </b-col>
            </b-row>
            <b-row style="padding-left: 0; padding-right: 0; height: calc(100% - 59px);">
                <b-col cols="12">
                    <b-container style="margin-top: 50px; border-radius: 4px; border: 1px solid lightgray; min-height: 500px; padding: 50px; font-family: Roboto; color: rgb(73, 80, 87); background-color: white;">
                        <b-row>
                            <b-col cols="2"></b-col>
                                <b-col cols="10">
                                    <h1>Register</h1>
                                    <b-row>
                                        <b-col cols="4">
                                            <b-form-group label="Full Name" label-for="registerName">
                                                <b-form-input required id="registerName" placeholder="Enter full name" v-model="user.fullname" tabindex="1">
                                                </b-form-input>
                                            </b-form-group>
                                            <b-form-group label="Company Name (not required)" label-for="registerCompany">
                                                <b-form-input id="registerCompany" placeholder="Enter company name" v-model="user.company" tabindex="2">
                                                </b-form-input>
                                            </b-form-group>
                                            <b-form-group label="Email" label-for="registerEmail">
                                                <b-form-input required type="email" id="registerEmail" placeholder="Enter email" v-model="user.email" tabindex="3">
                                                </b-form-input>
                                            </b-form-group>
                                            <p>
                                                By clicking create account, you agree to our <router-link to="/tos">Terms of service</router-link> and <a href="#" v-b-tooltip.hover title="Random Generated Token">Allowing required cookies</a>.
                                            </p>
                                            <b-button type="submit" variant="success" style="min-width: 125px;" @click="register()" tabindex="8">Create Account</b-button>
                                        </b-col>
                                        <b-col cols="4">
                                            <b-form-group label="Country" label-for="registerCountry">
                                                <b-form-select id="country" :options="countries" placeholder="Enter country name" v-model="user.country" tabindex="4">
                                                </b-form-select>
                                            </b-form-group>
                                            <b-form-group label="Password" label-for="registerPassword">
                                                <b-form-input required type="password" id="registerPassword" placeholder="Enter password" v-model="user.password" tabindex="5">
                                                </b-form-input>
                                            </b-form-group>
                                            <b-form-group label="Confirm Password" label-for="registerConfirmPassword">
                                                <b-form-input required type="password" id="registerConfirmPassword" placeholder="Confirm password" v-model="confirmPassword" tabindex="6">
                                                </b-form-input>
                                            </b-form-group>
                                            <vue-hcaptcha sitekey="1b99a4b3-b5e2-4ef6-ade9-2ec2ddd12bcc" @verify="onVerify" @expired="onExpired" @error="onError" required  tabindex="7"></vue-hcaptcha>
                                        </b-col>
                                    </b-row>
                                    <b-row style="margin-top: 50px;">
                                        <b-col>
                                            <span>Already have an account?</span><b-link style="color: rgb(2, 109, 201); margin-left: 5px;" to="/login"><strong>Login</strong></b-link>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            <b-col cols="2"></b-col>
                        </b-row>
                    </b-container>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

export default {
    name: "Register",
    components: {
        VueHcaptcha
    },
    data: () => {
        return {
            user: {
                fullname: "",
                company: "",
                email: "",
                country: "",
                password: "",
            },
            confirmPassword: "",
            recaptchaSecret: "",

            countries: [
                "Afghanistan",
                "Albania",
                "Algeria",
                "American Samoa",
                "Andorra",
                "Angola",
                "Anguilla",
                "Antarctica",
                "Antigua and Barbuda",
                "Argentina",
                "Armenia",
                "Aruba",
                "Australia",
                "Austria",
                "Azerbaijan",
                "Bahamas",
                "Bahrain",
                "Bangladesh",
                "Barbados",
                "Belarus",
                "Belgium",
                "Belize",
                "Benin",
                "Bermuda",
                "Bhutan",
                "Bolivia",
                "Bosnia and Herzegovina",
                "Botswana",
                "Bouvet Island",
                "Brazil",
                "British Indian Ocean Territory",
                "Brunei",
                "Bulgaria",
                "Burkina Faso",
                "Burundi",
                "Cambodia",
                "Cameroon",
                "Canada",
                "Cape Verde",
                "Cayman Islands",
                "Central African Republic",
                "Chad",
                "Chile",
                "China",
                "Christmas Island",
                "Cocos (Keeling) Islands",
                "Colombia",
                "Comoros",
                "Congo",
                "The Democratic Republic of Congo",
                "Cook Islands",
                "Costa Rica",
                "Ivory Coast",
                "Croatia",
                "Cuba",
                "Cyprus",
                "Czech Republic",
                "Denmark",
                "Djibouti",
                "Dominica",
                "Dominican Republic",
                "East Timor",
                "Ecuador",
                "Egypt",
                "England",
                "El Salvador",
                "Equatorial Guinea",
                "Eritrea",
                "Estonia",
                "Ethiopia",
                "Falkland Islands",
                "Faroe Islands",
                "Fiji Islands",
                "Finland",
                "France",
                "French Guiana",
                "French Polynesia",
                "French Southern territories",
                "Gabon",
                "Gambia",
                "Georgia",
                "Germany",
                "Ghana",
                "Gibraltar",
                "Greece",
                "Greenland",
                "Grenada",
                "Guadeloupe",
                "Guam",
                "Guatemala",
                "Guernsey",
                "Guinea",
                "Guinea-Bissau",
                "Guyana",
                "Haiti",
                "Heard Island and McDonald Islands",
                "Holy See (Vatican City State)",
                "Honduras",
                "Hong Kong",
                "Hungary",
                "Iceland",
                "India",
                "Indonesia",
                "Iran",
                "Iraq",
                "Ireland",
                "Israel",
                "Isle of Man",
                "Italy",
                "Jamaica",
                "Japan",
                "Jersey",
                "Jordan",
                "Kazakhstan",
                "Kenya",
                "Kiribati",
                "Kuwait",
                "Kyrgyzstan",
                "Laos",
                "Latvia",
                "Lebanon",
                "Lesotho",
                "Liberia",
                "Libyan Arab Jamahiriya",
                "Liechtenstein",
                "Lithuania",
                "Luxembourg",
                "Macao",
                "North Macedonia",
                "Madagascar",
                "Malawi",
                "Malaysia",
                "Maldives",
                "Mali",
                "Malta",
                "Marshall Islands",
                "Martinique",
                "Mauritania",
                "Mauritius",
                "Mayotte",
                "Mexico",
                "Micronesia, Federated States of",
                "Moldova",
                "Monaco",
                "Mongolia",
                "Montserrat",
                "Montenegro",
                "Morocco",
                "Mozambique",
                "Myanmar",
                "Namibia",
                "Nauru",
                "Nepal",
                "Netherlands",
                "Netherlands Antilles",
                "New Caledonia",
                "New Zealand",
                "Nicaragua",
                "Niger",
                "Nigeria",
                "Niue",
                "Norfolk Island",
                "North Korea",
                "Northern Ireland",
                "Northern Mariana Islands",
                "Norway",
                "Oman",
                "Pakistan",
                "Palau",
                "Palestine",
                "Panama",
                "Papua New Guinea",
                "Paraguay",
                "Peru",
                "Philippines",
                "Pitcairn",
                "Poland",
                "Portugal",
                "Puerto Rico",
                "Qatar",
                "Reunion",
                "Romania",
                "Russian Federation",
                "Rwanda",
                "Saint Helena",
                "Saint Kitts and Nevis",
                "Saint Lucia",
                "Saint Pierre and Miquelon",
                "Saint Vincent and the Grenadines",
                "Samoa",
                "San Marino",
                "Sao Tome and Principe",
                "Saudi Arabia",
                "Scotland",
                "Senegal",
                "Serbia",
                "Seychelles",
                "Sierra Leone",
                "Singapore",
                "Slovakia",
                "Slovenia",
                "Solomon Islands",
                "Somalia",
                "South Africa",
                "South Georgia and the South Sandwich Islands",
                "South Korea",
                "South Sudan",
                "Spain",
                "SriLanka",
                "Sudan",
                "Suriname",
                "Svalbard and Jan Mayen",
                "Swaziland",
                "Sweden",
                "Switzerland",
                "Syria",
                "Tajikistan",
                "Tanzania",
                "Thailand",
                "Timor-Leste",
                "Togo",
                "Tokelau",
                "Tonga",
                "Trinidad and Tobago",
                "Tunisia",
                "Turkey",
                "Turkmenistan",
                "Turks and Caicos Islands",
                "Tuvalu",
                "Uganda",
                "Ukraine",
                "United Arab Emirates",
                "United Kingdom",
                "United States",
                "United States Minor Outlying Islands",
                "Uruguay",
                "Uzbekistan",
                "Vanuatu",
                "Venezuela",
                "Vietnam",
                "Virgin Islands, British",
                "Virgin Islands, U.S.",
                "Wales",
                "Wallis and Futuna",
                "Western Sahara",
                "Yemen",
                "Yugoslavia",
                "Zambia",
                "Zimbabwe"
            ]
        }
    },
    mounted() {
        if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
            //verify the token.. :)
            this.$http.post(this.StacketConfig.api.auth + "/verify", {"token": token}).then(response => {
                if(response.body.state == "verified"){
                    this.$router.push("/dashboard");
                } else {
                    localStorage.removeItem("user-token");
                }
            }).catch(err => {
                console.log(err);
            })
        }
    },
    methods: {
        register: function(){
            console.log(this.user, this.confirmPassword, this.recaptchaSecret);
            if(this.user.fullname == ""){
                return this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Error",
                    text: "It looks like you haven't entered a full name!"
                })
            }
            if(this.user.email == ""){
                return this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Error",
                    text: "It looks like you haven't entered a email!"
                })
            }
            if(this.user.password == ""){
                return this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Error",
                    text: "It looks like you haven't entered a password!"
                })
            }
            if(this.user.confirmPassword == ""){
                return this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Error",
                    text: "It looks like you haven't confirmed your password!"
                })
            }
            if(this.user.password != this.confirmPassword){
                return this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Error",
                    text: "It looks like the passwords doesn't match!"
                })
            }
            if(this.user.country == ""){
                return this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Error",
                    text: "It looks like you haven't selected a country!"
                })
            }
            console.log(this.$http)
            this.$http.post(this.StacketConfig.api.auth + "/register", {"user": this.user, "hcaptcha": this.recaptchaSecret}).then(response => {
                if(response.body.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "Error",
                        text: response.body.error
                    })
                }
                if(response.body.type == "email"){
                    return this.$notify({
                        group: "notifications",
                        type: 'success',
                        title: "Registered!",
                        text: "We have sent you an verification email!"
                    })
                }
                this.$router.push("/login");
                //localStorage.setItem('user-token', response.body.token);
                //this.$router.push("/dashboard");
            }).catch(err => {
                return this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Error",
                    text: "An error accured while trying to register."
                })
            })
        },
        onVerify: function (response) {
            this.recaptchaSecret = response;
        },
        onExpired: function () {
            this.recaptchaSecret = "";
        },
        onError: function () {
            this.recaptchaSecret = "";
            return this.$notify({
                group: "notifications",
                type: 'error',
                title: "HCaptcha Error",
                text: "Something went wrong!"
            })
        }
    }
}
</script>