<template>
    <b-container fluid>
        <b-modal id="editFolder" @ok="saveFolder" @hidden="resetFolder" title="Edit Folder">
        <b-form-group
            label="Folder Name"
        >
        <b-form-input
            id="folderName"
            v-model="folder.name"
            required
            placeholder="Enter name"
        />
        </b-form-group>
            <b-form-group
                label="Add Service, Network or Disk to your folder"
            >
                <b-dropdown text="Select" class="m-md-2" style="background-color: #4886FD; border-color: #4886FD; border-radius: 4px;" variant="primary">
                    <span v-for="(service, key) in services" v-bind:key="key">
                        <b-dropdown-item v-if="(folder.items.find(e => e.type == 'service' && e.toString() == service._id.toString()) != null) == false" @click="folder.items.push({type: 'service', id: service._id})">
                            <b>Service - </b> {{ service.name }}
                        </b-dropdown-item>
                    </span>
                    <span v-for="(network, key) in networks" v-bind:key="key">
                        <b-dropdown-item v-if="(folder.items.find(e => e.type == 'network' && e.id.toString() == network._id.toString()) != null) == false" @click="folder.items.push({type: 'network', id: network._id})">
                            <b>Network - </b> {{ network.name }}
                        </b-dropdown-item>
                    </span>
                    <span v-for="(disk, key) in disks" v-bind:key="key">
                        <b-dropdown-item v-if="(folder.items.find(e => e.type == 'disk' && e.id.toString() == disk._id.toString()) != null) == false" @click="folder.items.push({type: 'disk', id: disk._id})">
                            <b>Disk - </b> {{ disk.name }}
                        </b-dropdown-item>
                    </span>
                </b-dropdown>
            </b-form-group>
            <b-form-group
                v-bind:label="'Added Services / Networks / Drives (' + (folder.items.length) + ')'"
            >
                <b-list-group>
                    <span v-for="(serviceId, key) in folder.items.filter(e => e.type == 'service')" v-bind:key="key">
                        <b-list-group-item @click="deleteNewFolderThing(serviceId.id)" class="newFolderListItem">
                            {{ services.find(s => s._id == serviceId.id).name }}
                            <span style="float: right; font-weight: bold;">
                                Service
                            </span>
                        </b-list-group-item>
                    </span>
                    <span v-for="(networkId, key) in folder.items.filter(e => e.type == 'network')" v-bind:key="key">
                        <b-list-group-item @click="deleteNewFolderThing(networkId.id)" class="newFolderListItem">
                            {{ networks.find(s => s._id == networkId.id).name }}
                            <span style="float: right; font-weight: bold;">
                                Network
                            </span>
                        </b-list-group-item>
                    </span>
                    <span v-for="(diskId, key) in folder.items.filter(e => e.type == 'disk')" v-bind:key="key">
                        <b-list-group-item @click="deleteNewFolderThing(diskId.id)" class="newFolderListItem">
                            {{ disks.find(s => s._id == diskId.id).name }}
                            <span style="float: right; font-weight: bold;">
                                Disk
                            </span>
                        </b-list-group-item>
                    </span>
                </b-list-group>
            </b-form-group>
        </b-modal>
        <b-row style="margin-top: 50px; color: rgb(73, 80, 87)!important;">
            <b-col cols="1"></b-col>
            <b-col class="mt-4" style="min-width: 375px; margin-bottom: 25px;">
                <b-row>
                    <b-col cols="2">
                        <router-link to="/dashboard" style="text-decoration: none;">
                            <h6 style="margin-top: 20px;"><i class="fas fa-chevron-left"></i> My Dasboard</h6>
                        </router-link>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4">
                        <h2>{{ folder.name }}</h2>
                    </b-col>
                    <b-col>
                        <span @click="FolderEdit()"><i class="fas fa-edit editIcon mt-2" style="color:lightgray; font-size: 1.5rem;"></i></span>
                        <span style="text-align: center;" @click="deleteFolder()"><i class="fas fa-trash-alt trashIcon" style="margin-left: 15px; color:lightgray; font-size: 1.5rem"></i></span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p>{{ folder._id }}</p>
                    </b-col>
                </b-row>
                <div style="border: 0.5px solid #DDDEE1; background-color: #FFFFFF; border-radius: 4px; padding: 20px; min-height: 400px;">
                    <b-row style="margin-top: 15px;">
                        <b-col cols="3" style="min-width: 375px; margin-bottom: 25px;" v-for="(item, index) of folder.items" v-bind:key="index">
                            <networkCard v-if="item.type == 'network'" :network="networks.find(n => n._id.toString() == item.id.toString())"></networkCard>
                            <serviceCard v-else-if="item.type == 'service'" :service="services.find(s => s._id.toString() == item.id.toString())"></serviceCard>
                            <diskCard v-else-if="item.type == 'disk'" :disk="disks.find(d => d._id.toString() == item.id.toString())"></diskCard>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col cols="1"></b-col>
        </b-row>
    </b-container>
</template>

<style lang="scss">

</style>

<script>
    import networkCard from '@/components/networkCard.vue';
    import serviceCard from "@/components/serviceCard.vue";
    import diskCard from "@/components/diskCard.vue";

    export default {
        name: "folder",
        components: {
            networkCard,
            serviceCard,
            diskCard
        },
        data: () => {
            return {
                token: "",
            }
        },
        props: [
            "user",
            "networks",
            "services",
            "disks",
            "folders",
            "folder"
        ],
        mounted() {
            this.token = localStorage.getItem("user-token");
            if(localStorage.getItem("user-token")){
                const token = localStorage.getItem("user-token");

                
            }
        },
        methods: {
            getPrice(gb){
                var price = {
                    "20": 0.20,
                    "50": 0.15,
                    "100": 0.12,
                    "250": 0.10,
                    "500": 0.08,
                    "normal": 0.06
                }
                if(gb <= 20) return gb * price["20"];
                if(gb <= 50) return gb * price["50"];
                if(gb <= 100) return gb * price["100"];
                if(gb <= 250) return gb * price["250"];
                if(gb <= 500) return gb * price["500"];
                return gb * price["normal"];
            },
            deleteNewFolderThing(id){
                this.folder.items = this.folder.items.filter(e => e.id !== id);
            },
            resetFolder(){
                this.$parent.getFolders();
            },
            saveFolder(){
                var fold = Object.assign({}, this.folder);
                this.$http.post(this.StacketConfig.api.profile + `/folders/${this.folder._id}`, fold, {headers: {"authorization": this.token}}).then(response => {
                    if(response.data.error) {
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error
                        });
                    }
                    this.$parent.getFolders();
                }).catch(err => {
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not edit folder!"
                    });
                })
            },
            deleteFolder(){
                this.$http.delete(this.StacketConfig.api.profile + `/folders/${this.folder._id}`, {headers: {"authorization": this.token}}).then(response => {
                    if(response.data.error) {
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error
                        });
                    }
                    this.$parent.getFolders();
                    this.$router.replace('/dashboard');
                }).catch(err => {
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not delete network!"
                    });
                })
            },
            FolderEdit() {
                this.$bvModal.show("editFolder");
            }
        }
    }
</script>