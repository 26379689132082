<template>
    <b-container fluid>
        <b-row class="mt-4">
            <b-col>
                <b-tabs>
                    <b-tab title="File Manager">
                        <b-alert variant="danger" style="margin-top: 25px;" show>Not available yet!</b-alert>
                        <!--<b-row style="margin-top: 25px;">
                            <b-col>
                                Path: {{ path }}
                            </b-col>
                        </b-row>
                        <b-row v-for="(file, index) of files" v-bind:key="index" style="background-color: white; margin-bottom: 5px;">
                            <b-col @click="getFiles(path + file + '/')">
                                {{ file }}
                            </b-col>
                        </b-row>-->
                    </b-tab>
                    <b-tab title="FTP" active>
                        <b-row>
                            <b-col cols="3" v-for="(ftp, index) of ftpusers" v-bind:key="index" style="min-width: 326px;">
                                <b-card-group deck class="mt-3">
                                    <b-card body-tag="div" header-bg-variant="white" header-tag="header" style="border-color: #eff0f5;" class="serviceCard shadow-xl">
                                        <template v-slot:header>
                                            <h6 class="mb-0" style="color: #777676;">
                                                <b-row>
                                                    <b-col>
                                                        <span style="color: #777676;" v-if="ftpEditing != ftp._id">
                                                        {{ ftp.name }}
                                                        </span>
                                                        <span v-else>
                                                            <b-form-input v-model="ftpEditData.name" placeholder="Enter FTP user name" style="font-size: 1rem; height: 20px;"></b-form-input>
                                                        </span>
                                                    </b-col>
                                                    <b-col>
                                                        <span style="color: #777676; position: absolute; right: 10px;">
                                                            <b-row>
                                                                <b-col cols="5">
                                                                    <a v-bind:href="'winscp-ftp://'+ftp._id+':'+ftp.password+'@'+$parent.network.ip+':21'">
                                                                        <b-button v-b-tooltip.hover title="Open in WinSCP" style="font-size: 10px; margin-top: -5px; font-weight: bold;" variant="primary">
                                                                            WinSCP
                                                                        </b-button>
                                                                    </a>
                                                                </b-col>
                                                                <b-col style="text-align: center;" @click="toggleEditFTP(ftp)" cols="1" v-if="ftpEditing != ftp._id">
                                                                    <i class="fas fa-edit editIcon" style="color:lightgray;"></i>
                                                                </b-col>
                                                                <b-col style="text-align: center;" @click="saveEditFTP(ftp)" cols="1" v-else>
                                                                    <i class="fas fa-save saveIcon" style="color:lightgray;"></i>
                                                                </b-col>
                                                                <b-col style="text-align: center;" @click="deleteFTP(ftp._id)" cols="1">
                                                                    <i class="fas fa-trash-alt trashIcon" style="color:lightgray;"></i>
                                                                </b-col>
                                                            </b-row>
                                                        </span>
                                                    </b-col>
                                                </b-row>
                                            </h6>
                                        </template>
                                        <div v-if="ftpEditing != ftp._id">
                                            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px; border-top-color: transparent;">
                                                <b-row>
                                                    <b-col style="margin-left: -15px;">
                                                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                                                            Username
                                                        </h6>
                                                    </b-col>
                                                    <b-col>
                                                        <h6 style="text-align: right; font-size: 13px">
                                                            {{ ftp._id }}
                                                        </h6>
                                                    </b-col>
                                                </b-row>
                                            </b-card-footer>
                                            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                                                <b-row>
                                                    <b-col style="margin-left: -15px;">
                                                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                                                            Password
                                                        </h6>
                                                    </b-col>
                                                    <b-col @mouseenter="ftp.active = true" @mouseleave="ftp.active = false">
                                                        <h6 style="text-align: right; font-size: 13px;" v-if="ftp.active == true">
                                                            {{ ftp.password }}
                                                        </h6>
                                                        <h6 style="text-align: right; font-size: 13px; color: transparent; text-shadow: 0 0 2px rgba(0,0,0,0.5);" v-else="">
                                                            Hover to display
                                                        </h6>
                                                    </b-col>
                                                </b-row>
                                            </b-card-footer>
                                            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                                                <b-row>
                                                    <b-col style="margin-left: -15px;">
                                                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                                                            File Path
                                                        </h6>
                                                    </b-col>
                                                    <b-col>
                                                        <h6 style="text-align: right; font-size: 13px">
                                                            {{ ftp.path }}
                                                        </h6>
                                                    </b-col>
                                                </b-row>
                                            </b-card-footer>
                                            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                                                <b-row>
                                                    <b-col style="margin-left: -15px;">
                                                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                                                            IP Address
                                                        </h6>
                                                    </b-col>
                                                    <b-col>
                                                        <h6 style="text-align: right; font-size: 13px">
                                                            {{ $parent.network.ip }}
                                                        </h6>
                                                    </b-col>
                                                </b-row>
                                            </b-card-footer>
                                            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                                                <b-row>
                                                    <b-col style="margin-left: -15px;">
                                                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                                                            Port
                                                        </h6>
                                                    </b-col>
                                                    <b-col>
                                                        <h6 style="text-align: right; font-size: 13px">
                                                            21
                                                        </h6>
                                                    </b-col>
                                                </b-row>
                                            </b-card-footer>
                                        </div>
                                        <div v-else>
                                            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px; border-top-color: transparent;">
                                                <b-row>
                                                    <b-col style="margin-left: -15px;">
                                                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                                                            Username
                                                        </h6>
                                                    </b-col>
                                                    <b-col>
                                                        <h6 style="text-align: right; font-size: 13px">
                                                            {{ ftp._id }}
                                                        </h6>
                                                    </b-col>
                                                </b-row>
                                            </b-card-footer>
                                            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                                                <b-row>
                                                    <b-col style="margin-left: -15px;">
                                                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                                                            Password
                                                        </h6>
                                                    </b-col>
                                                    <b-col>
                                                        <h6 style="text-align: right; font-size: 13px;">
                                                            <b-form-input v-model="ftpEditData.password" placeholder="Enter password" style="font-size: 13px; height: 20px;"></b-form-input>
                                                        </h6>
                                                    </b-col>
                                                </b-row>
                                            </b-card-footer>
                                            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                                                <b-row>
                                                    <b-col style="margin-left: -15px;">
                                                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                                                            File Path
                                                        </h6>
                                                    </b-col>
                                                    <b-col>
                                                        <h6 style="text-align: right; font-size: 13px">
                                                            <b-form-input v-model="ftpEditData.path" placeholder="Enter file path" style="font-size: 13px; height: 20px;"></b-form-input>
                                                        </h6>
                                                    </b-col>
                                                </b-row>
                                            </b-card-footer>
                                            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                                                <b-row>
                                                    <b-col style="margin-left: -15px;">
                                                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                                                            IP Address
                                                        </h6>
                                                    </b-col>
                                                    <b-col>
                                                        <h6 style="text-align: right; font-size: 13px">
                                                            {{ $parent.network.ip }}
                                                        </h6>
                                                    </b-col>
                                                </b-row>
                                            </b-card-footer>
                                            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                                                <b-row>
                                                    <b-col style="margin-left: -15px;">
                                                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                                                            Port
                                                        </h6>
                                                    </b-col>
                                                    <b-col>
                                                        <h6 style="text-align: right; font-size: 13px">
                                                            21
                                                        </h6>
                                                    </b-col>
                                                </b-row>
                                            </b-card-footer>
                                        </div>
                                    </b-card>
                                </b-card-group>
                            </b-col>
                        </b-row>
                        <b-row style="margin-top: 50px;">
                            <b-col>
                                <h5>
                                    New FTP User
                                </h5>
                            </b-col>
                        </b-row>
                        <b-row class="mt-4">
                            <b-col>
                                Name
                            </b-col>
                            <b-col>
                                Password
                            </b-col>
                            <b-col>
                                Path
                            </b-col>
                            <b-col></b-col>
                        </b-row>
                        <b-row class="mt-4">
                            <b-col>
                                <b-input-group>
                                    <b-form-input type="text" v-model="newFtp.name" placeholder="Name"></b-form-input>
                                </b-input-group>
                            </b-col>
                            <b-col>
                                <b-input-group>
                                    <b-form-input type="text" v-model="newFtp.password" placeholder="Password"></b-form-input>
                                </b-input-group>
                            </b-col>
                            <b-col>
                                <b-input-group>
                                    <b-form-input type="text" v-model="newFtp.path" placeholder="Path"></b-form-input>
                                </b-input-group>
                            </b-col>
                            <b-col>
                                <b-button variant="primary" @click="newFTP()" style="margin-right: 10px;">Add</b-button>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
            </b-col>
        </b-row>
    </b-container>
</template>

<style lang="scss">
    .trashIcon {
        cursor: pointer;
        color: lightgray!important;
        transition: 0.15s;
    }
    .trashIcon:hover {
        color: #f13862!important;
    }

    .editIcon {
        cursor: pointer;
        color: lightgray!important;
        transition: 0.15s;
    }

    .editIcon:hover {
        color: #4886FD!important;
    }

    .saveIcon {
        cursor: pointer;
        color: lightgray!important;
        transition: 0.15s;
    }

    .saveIcon:hover {
        color: #28a745!important
    }
</style>

<script>

export default {
    name: "File Manager",
    components: {
        
    },
    data: () => {
        return {
            token: "",
            service: {},
            ftpusers: [],
            newFtp: {
                name: "",
                password: "",
                path: "/"
            },
            ftpEditing: "",
            ftpEditData: {
                password: "",
                path: "",
                name: ""
            },
            files: [],
            path: "/",
        }
    },
    mounted() {
        this.token = localStorage.getItem("user-token");
        this.service = this.$parent.service;
        this.getFiles("/")
        this.getFtpUsers();
    },
    methods: {
        getFiles(path){
            this.$http.get(this.StacketConfig.api.services + "/" + this.$route.params.page + "/files" + path, {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.path = path;
                this.files = response.data;

            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch ftp users!"
                })
            });
        },
        deleteFTP(id) {
            this.$http.delete(this.StacketConfig.api.services + "/" + this.$route.params.page + "/ftp/" + id, {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Success",
                    text: "FTP user deleted!"
                })
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not delete FTP user!"
                })
            });
        },
        newFTP() {
            if(this.newFtp.name == "" ||this.newFtp.password == "" || this.newFtp.path == "" || !this.newFtp.path.startsWith("/")){
                return this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Invalid parameters",
                    text: "Please enter all information."
                })
            }
            this.$http.post(this.StacketConfig.api.services + "/" + this.$route.params.page + "/ftp", this.newFtp, {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                var newree = response.data;
                newree.active = false;
                this.ftpusers.push(newree);
                this.$notify({
                    group: "notifications",
                    type: 'succes',
                    title: "Success",
                    text: "FTP user added!"
                })
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not add FTP user!"
                })
            });
        },
        getFtpUsers() {
            this.$http.get(this.StacketConfig.api.services + "/" + this.$route.params.page + "/ftp", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.ftpusers = response.data.map(element => {
                    element.active = false;
                    return element
                });
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch ftp users!"
                })
            });
        },
        toggleEditFTP(ftp) {
            this.ftpEditData.password = ftp.password;
            this.ftpEditData.path = ftp.path;
            this.ftpEditData.name = ftp.name;
            this.ftpEditing = ftp._id;
        },
        saveEditFTP(oldFtp) {
            let ftp = this.ftpusers.find(_ftp => _ftp._id == oldFtp._id);
            console.log(ftp);

            ftp.password = this.ftpEditData.password;
            ftp.path = this.ftpEditData.path;
            ftp.name = this.ftpEditData.name;

            this.ftpEditing = "";

            this.ftpEditData = {
                password: null,
                path: null,
                name: null
            };

            let req = ftp;
            req.id = ftp._id;

            this.$http.post(this.StacketConfig.api.services + "/" + this.$route.params.page + "/ftp", req, {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }

                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Success",
                    text: "FTP user updated!"
                })
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not update FTP user!"
                })
            });
        }
    }
}  
</script>