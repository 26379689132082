<template>
    <div @click="gotoFolder()" class="folderCard">
        <b-row style="padding-top: 10px;">
            <b-col cols="4">
                <i class="fas fa-folder" style="margin-left: 15px; margin-right: 20px; font-size: 50px; opacity: 0.9; color: white;"></i>
            </b-col>
            <b-col>
                <div style="color: white; font-weight: bold; font-size: 17px;">
                    {{ folder.name }}
                </div>
                <p style="color: white; font-weight: normal; font-size: 13px; margin-top: 0px;">
                    {{ folder.items.length }} Services
                </p>
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss">
    .folderCard {
        cursor: pointer;
        height: 100%;
        min-width: 200px;
        min-height: 50px;
        border-radius: 10px;
        background: linear-gradient(0deg, rgba(22,110,220,1) 0%, rgba(98,168,255,1) 100%);

        transition: 0.20s;
    }
    .folderCard:hover {
        transform: scale(1.025);
        background: linear-gradient(0deg, rgb(21, 106, 211) 0%, rgb(89, 152, 230) 100%);
    }
</style>

<script>

export default {
    name: "serviceCard",
    components: {
        
    },
    data: () => {
        return {
            token: "",
        }
    },
    props: [
        "folder",
        "services",
        "networks",
        "disks"
    ],
    mounted() {
        this.token = localStorage.getItem("user-token");
        if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
        }
    },
    methods: {
       gotoFolder(){
            this.$router.replace('/dashboard/folders/' + this.folder._id);
       }
    }
}  

</script>