import Vue from 'vue';
import App from './App.vue';

import router from './router.js';

Vue.config.productionTip = false;

import VueResource from "vue-resource"
Vue.use(VueResource);

import { BootstrapVue, IconsPlugin, LayoutPlugin, ModalPlugin, CardPlugin, VBScrollspyPlugin, DropdownPlugin, TablePlugin } from 'bootstrap-vue';
Vue.use(BootstrapVue, IconsPlugin, LayoutPlugin, ModalPlugin, CardPlugin, VBScrollspyPlugin, DropdownPlugin, TablePlugin);

import Notifications from 'vue-notification'
Vue.use(Notifications)

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading);

import VueTimeago from 'vue-timeago';
Vue.use(VueTimeago, {
  "name": 'Timeago',
  "locale": 'en'
});


import crono from 'vue-crono';
Vue.use(crono);
import Popper from 'popper.js'; 
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;


var getLang = extractTldFromHost(window.location.href) ? extractTldFromHost(window.location.href) : "net";

//config
Vue.prototype.StacketConfig = {
  api: {
    auth: "https://auth_devapi.stacket." + getLang,
    services: "https://services_devapi.stacket." + getLang,
    profile: "https://profile_devapi.stacket." + getLang,
    networks: "https://networks_devapi.stacket." + getLang,
    teams: "https://teams_devapi.stacket." + getLang,
    news: "https://news_devapi.stacket." + getLang,
    guides: "https://guides_devapi.stacket." + getLang,
    payment: "https://payment_devapi.stacket." + getLang,
    disks: "https://disks_devapi.stacket." + getLang
  }
}

Vue.prototype.Langs = {
  net: require("./languages/en.json"),
  dk: require("./languages/da.json")
}

//var getLang = navigator.language || navigator.userLanguage;

if(Vue.prototype.Langs[getLang.toString().toLowerCase().split("-")[0]]){
  Vue.prototype.Lang = nestedassign(Object.create(Vue.prototype.Langs[getLang.toString().toLowerCase().split("-")[0]]),Vue.prototype.Langs["dk"]);
} else {
  Vue.prototype.Lang = Vue.prototype.Langs["net"]
}


function extractTldFromHost(hostname) {
  var lastDotIndex = hostname.lastIndexOf('.');
  if (lastDotIndex === -1) {
    return null;
  }

  return hostname.substr(lastDotIndex + 1);
};

function nestedassign(target, source) {
  Object.keys(source).forEach(sourcekey=>{
    if (Object.keys(source).find(targetkey=>targetkey===sourcekey) !== undefined && typeof source[sourcekey] === "object") {
      target[sourcekey]=nestedassign(target[sourcekey],source[sourcekey]);
    } else {
      target[sourcekey]=source[sourcekey];
    }
  });
  return target;
}



new Vue({
  render: h => h(App),
  router
}).$mount('#app')
