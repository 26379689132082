<template>
    <b-container fluid>
        <b-row style="margin-top: 50px; margin-bottom: 25px;">
            <b-col>
                <h4>
                    Nodes
                </h4>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-row>
                    <b-col cols="2" v-for="(node, index) of usage" v-bind:key="index" style="margin-bottom: 25px;">
                        <p>
                            <b>Node</b> {{ node.node }}
                        </p>
                        <p>
                            <b>CPU Usage</b> {{ node.cpu.toFixed(4) }}%
                        </p>
                        <p>
                            <b>Memory Usage</b>  {{ (node.memory.used / 1024).toFixed(2) }} / {{ (node.memory.total / 1024).toFixed(2) }} GB
                        </p>
                        <p>
                            <b>Memory Allocated</b> {{ allocated(node.node) }} GB
                        </p>
                        <p>
                            <b>Services Active</b> {{ services.filter(srv => srv.node == node.node && srv.status != 'hibernating').length }} / {{ services.filter(srv => srv.node == node.node).length }}
                        </p>
                        <p v-if="node.node == 'fsn2'">
                            <b>Earnings Per Hour</b> ${{ (earnings.additional / 720).toFixed(4) }}
                        </p>
                        <p v-else>
                            <b>Earnings Per Hour</b> ${{ (allocated(node.node) * (1.5 / 720)).toFixed(4) }}
                        </p>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>

</script>
<style>
    .copyLink {
        float:right;
        cursor: pointer;
        color: #c9c8c8!important;
        transition: 0.15s;
    }
    .copyLink:hover {
        color: #9c9c9c!important;
    }
    .productIcons {
        cursor: pointer;
        color: #c9c8c8!important;
        transition: 0.15s;
    }
    .productIcons:hover {
        color: #6b6b6b!important;
    }
    .serviceCard{
        transition: all .2s ease-in-out;
    }
    .serviceCard:hover {
        transform: scale(1.025);
    }
</style>
<script>
import serviceCard from '@/components/serviceCard.vue';

export default {
    name: "Staff",
    components: {
        serviceCard
    },
    data: () => {
        return {
            token: "",
            services: [],
            usage: [],
            earnings: {
                services: 0,
                amount: 0.00,
                additional: 0.00,
                dedicatedservers: 0.00,
            },
            nodes: {
                "fsn5": true,
                "fsn6": true,
                "fsn7": true,
                "fsn8": true,
                "fsn9": true,
                "fsn10": true,
                "fsn11": true,
                "fsn12": true,
                "fsn13": true,
                "fsn14": true,
            },
            types: {
                "minecraft": true,
                "terraria": true,
                "csgo": true,
                "nodejs": true,
                "nginx": true,
                "nginx-php": true,
                "mysql": true,
            },
            status: {
                "online": true,
                "offline": true,
                "hibernating": true,
            }
        }
    },
    mounted() {
        this.token = localStorage.getItem("user-token");
        if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
            //verify the token.. :)
            this.$http.post(this.StacketConfig.api.auth + "/verify", {"token": token}).then(response => {
                if(response.body.state == "verified"){
                    this.doRequests();
                } else {
                    localStorage.removeItem("user-token");
                    return this.$router.push("/login");
                }
            }).catch(err => {
                console.log(err);
            })
        }
    },
    cron: {
        method: 'getStaffUsage',
        time: 10000
    },
    methods: {
        allocated(node){
            var srvs = this.services.filter(srv => srv.node == node && srv.status != 'hibernating');

            var ram = function(pkg){
                if(!pkg) return 0;
                var clean = parseInt(pkg.replace("pkg", ""));
                if(clean == 0) clean = 0.5;
                return clean;
            }

            var reducer = function(e, v){
                return e + ram(v.package);
            }
            return srvs.reduce(reducer, 0)
        },
        async doRequests(){
            var Vue = this;
            const requests = [
                this.$http.get(this.StacketConfig.api.services + "/systemUsage", {headers: {"authorization": this.token}}),
                this.$http.get(this.StacketConfig.api.services + "/staff", {headers: {"authorization": this.token}})
            ];
            Promise.all(requests).then(async ([systemUsageResponse, staffResponse]) => {
                if(systemUsageResponse.data.error){
                    return Vue.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: systemUsageResponse.data.error
                    })
                }
                Vue.usage = systemUsageResponse.data;

                if(staffResponse.data.error){
                    return Vue.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: staffResponse.data.error
                    })
                }
                Vue.services = staffResponse.data;
                for(var service in Vue.services){
                    if(!Vue.services[service].team){
                        if(Vue.services[service].subusers && Vue.services[service].subusers.length != 0){
                            var resp = await Vue.$http.get(Vue.StacketConfig.api.services + `/${Vue.services[service]._id}/subusers`, {headers: {"authorization": Vue.token}});
                            Vue.services[service].subusers = resp.data;    
                        }
                    } else {
                        if(Vue.services[service].team.groups && Vue.services[service].team.groups.length != 0){
                            var resp = await Vue.$http.get(Vue.StacketConfig.api.services + `/${Vue.services[service]._id}/groups`, {headers: {"authorization": Vue.token}});
                            Vue.services[service].team.groups = resp.data;    
                        }
                    }
                }
            })
        },
        getStaffUsage(){
            this.$http.get(this.StacketConfig.api.services + "/systemUsage", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.usage = response.data;
            }).catch(err => {
                loader.hide();
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch node usage!"
                })
            });
        },
        convertName(name) {
            var fullname = name+"";
            var split = fullname.split(" ");
            if(split.length == 1){
                return split[0].charAt(0) + split[0].charAt(1);
            } else {
                var firstName = split[0];
                var lastName = split[split.length-1];

                return firstName.charAt(0) + lastName.charAt(0);
            }

        },
        star(index, serviceId) {
            this.$http.post(this.StacketConfig.api.profile + "/star", {service: serviceId}, {headers: {"authorization": this.token}}).then((response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                if(response.data == "starred"){
                    this.$parent.user.starred.push(serviceId)
                } else if(response.data == "unstarred"){
                    for(var star in this.$parent.user.starred){
                        this.$parent.user.starred.splice(star + 1, 1);
                    }
                }
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not star/unstar service!"
                })
            });
        },

        getStaffServices() {
            let loader = this.$loading.show({loader: "bars", color: "#4886fd"});
            this.$http.get(this.StacketConfig.api.services + "/staff", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    loader.hide();
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                loader.hide();
                this.services = response.data;
                for(var service in this.services){
                    if(!this.services[service].team){
                        if(this.services[service].subusers && this.services[service].subusers.length != 0){
                            var resp = await this.$http.get(this.StacketConfig.api.services + `/${this.services[service]._id}/subusers`, {headers: {"authorization": this.token}});
                            this.services[service].subusers = resp.data;    
                        }
                    } else {
                        if(this.services[service].team.groups && this.services[service].team.groups.length != 0){
                            var resp = await this.$http.get(this.StacketConfig.api.services + `/${this.services[service]._id}/groups`, {headers: {"authorization": this.token}});
                            this.services[service].team.groups = resp.data;    
                        }
                    }
                }
                this.$parent.services = this.services;
            }).catch(err => {
                loader.hide();
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch services!"
                })
            });
        }
    }
}  
</script>