<template>
    <b-container fluid style="color: rgb(73, 80, 87); font-family: Roboto;">
        <b-row class="mt-5 ml-2">
            <b-col>
                <b-tabs content-class="mt-4" v-model="tabIndex">
                    <b-tab title="Account" active>
                        <div style="border: 0.5px solid #DDDEE1; background-color: #FFFFFF; border-radius: 4px; padding: 20px;">
                            <b-row>
                                <b-col cols="1"></b-col>
                                <b-col cols="4">
                                    <h5>Profile</h5>
                                    <p>Your identity on Stacket.</p>
                                    <b>
                                        User ID
                                    </b>
                                    <p>
                                        {{ profile._id }}
                                    </p>
                                </b-col>
                                <b-col cols="1">
                                    <img :src="profile.image" width=75px style="border-radius: 50%; border: 1px solid lightgray;">
                                </b-col>
                                <b-col class="mt-2" cols="5">
                                    <h6>Name</h6>
                                    <b-form-input placeholder="Enter your name" v-model="newProfile.fullname">

                                    </b-form-input>
                                    <h6 class="mt-3">Email Address</h6>
                                    <b-form-input placeholder="Enter your email address" type="email" v-model="newProfile.email">

                                    </b-form-input>
                                    <h6 class="mt-3">Company</h6>
                                    <b-form-input placeholder="Enter your company name" v-model="newProfile.company">

                                    </b-form-input>

                                    <b-button variant="primary" class="mt-3" @click="updateProfile" v-if="profile.fullname != newProfile.fullname || profile.email != newProfile.email || profile.company != newProfile.company">
                                        Save Changes
                                    </b-button>
                                    <b-button variant="primary" class="mt-3" disabled v-else title="No changes have been applied!">
                                        Save Changes
                                    </b-button>
                                </b-col>
                            </b-row>
                        </div>
                        <div style="border: 0.5px solid #DDDEE1; background-color: #FFFFFF; border-radius: 4px; padding: 20px;" class="mt-3">
                            <b-row>
                                <b-col cols="1"></b-col>
                                <b-col cols="4">
                                    <h5>GDPR</h5>
                                    <p>The EU General Data Protection Regulation requires Stacket to show all the data we store about you.</p>
                                </b-col>
                                <b-col cols="1">
                                    
                                </b-col>
                                <b-col class="mt-2" cols="5">
                                    <b-button variant="primary" class="mt-3" @click="downloadGDPR" title="This feature is not supported yet">
                                        Download
                                    </b-button>
                                </b-col>
                            </b-row>
                        </div>
                        <div style="border: 0.5px solid #DDDEE1; background-color: #FFFFFF; border-radius: 4px; padding: 20px;" class="mt-3">
                            <b-row>
                                <b-col cols="1"></b-col>
                                <b-col cols="4">
                                    <h5>U2F Authentication</h5>
                                    <p>
                                        Setup U2F Authentication requirement for your Stacket account.
                                    </p>
                                </b-col>
                                <b-col cols="1"></b-col>
                                <b-col class="mt-2" cols="5">
                                    <span v-if="!profile.u2f">
                                        <b-button variant="primary" class="mt-3" @click="setupu2f" title="Setup U2F Authentication">
                                            Setup
                                        </b-button>
                                    </span>
                                    <span v-else>
                                        <b-button variant="danger" class="mt-3" @click="removeu2f" title="Disable U2F Authentication">
                                            Disable
                                        </b-button>
                                    </span>
                                </b-col>

                            </b-row>
                        </div>
                    </b-tab>
                    <b-tab title="Billing">
                        <div style="border: 0.5px solid #DDDEE1; background-color: #FFFFFF; border-radius: 4px; padding: 20px;">
                            <b-row>
                                <b-col cols="1"></b-col>
                                <b-col cols="4" class="mt-3">
                                    <h5>Payment Method</h5>
                                    <p>Choose whether or not to fill your balance manually.</p>
                                </b-col>
                                <b-col cols="1">
                                    
                                </b-col>
                                <b-col class="mt-4" cols="5">
                                    <b-form-select v-model="billing.method" :options="[{value: 'manual', text: 'Manual'},{value: 'automatic', text: 'Automatic', disabled: true}]"></b-form-select>
                                </b-col>
                            </b-row>
                        </div>
                        <div style="border: 0.5px solid #DDDEE1; background-color: #FFFFFF; border-radius: 4px; padding: 20px;" class="mt-3">
                            <b-row style="margin-bottom: 50px;">
                                <b-col cols="1"></b-col>
                                <b-col cols="4" class="mt-3">
                                    <h5>Refill Balance</h5>
                                    <p>Need to add more money to your balance?</p>
                                </b-col>
                                <b-col cols="3" class="mt-3" style="text-align: center;">
                                    <h5>Current Balance</h5>
                                    <p>${{ profile.balance.toFixed(2) }} USD</p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="1"></b-col>
                                <b-col cols="10" class="mt-3">
                                    <div :class="(billing.amount == 5) ? 'selectedBillingButton billingButton' : 'billingButton'" @click="billing.amount = 5">
                                        <h3>$5 <small>USD</small></h3>
                                    </div>
                                    <div :class="(billing.amount == 10) ? 'selectedBillingButton billingButton' : 'billingButton'" @click="billing.amount = 10">
                                        <h3>$10 <small>USD</small></h3>
                                    </div>
                                    <div :class="(billing.amount == 15) ? 'selectedBillingButton billingButton' : 'billingButton'" @click="billing.amount = 15">
                                        <h3>$15 <small>USD</small></h3>
                                    </div>
                                    <div :class="(billing.amount == 20) ? 'selectedBillingButton billingButton' : 'billingButton'" @click="billing.amount = 20">
                                        <h3>$20 <small>USD</small></h3>
                                    </div>
                                    <div :class="(billing.amount == 25) ? 'selectedBillingButton billingButton' : 'billingButton'" @click="billing.amount = 25">
                                        <h3>$25 <small>USD</small></h3>
                                    </div>
                                    <div :class="(billing.amount == 50) ? 'selectedBillingButton billingButton' : 'billingButton'" @click="billing.amount = 50">
                                        <h3>$50 <small>USD</small></h3>
                                    </div>
                                    <div :class="(billing.amount == 75) ? 'selectedBillingButton billingButton' : 'billingButton'" @click="billing.amount = 75">
                                        <h3>$75 <small>USD</small></h3>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12" class="mt-3">
                                    <div style="width: 95%; text-align: center;" class="mt-3">
                                        <b-button v-if="stripe != null" variant="primary" @click="purchase()" style="width: 25%;">
                                            <i class="fas fa-credit-card"></i> Purchase
                                        </b-button>
                                        <b-button v-else variant="primary" disabled style="width: 25%;" title="Loading payment api">
                                            <i class="fas fa-credit-card"></i> Purchase
                                        </b-button>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                    <b-tab title="Invoices">
                        <div style="border: 0.5px solid #DDDEE1; background-color: #FFFFFF; border-radius: 4px; padding: 20px;">
                            <b-row style="margin-bottom: 50px;">
                                <b-col cols="3">
                                    <h5>Invoice Preview - {{ getMonthName(new Date()) }} {{ new Date().getFullYear() }}</h5>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="2">
                                    <h6>Service ID</h6>
                                </b-col>
                                <b-col cols="3">
                                    <h6>Name</h6>
                                </b-col>
                                <b-col cols="1">
                                    <h6>Hours</h6>
                                </b-col>
                                <b-col cols="1">
                                    <h6>Price</h6>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <hr>
                                </b-col>
                            </b-row>
                            <span v-for="(service, index) of lastPayment.services" v-bind:key="index">
                                <b-row>
                                    <b-col cols="2">
                                        {{ service.id || "Custom" }}
                                    </b-col>
                                    <b-col cols="3" v-if="service.note || services.find(srv => srv._id.toString() == service.id.toString())">
                                        {{ service.note || (services.find(srv => srv._id.toString() == service.id.toString()) ? services.find(srv => srv._id.toString() == service.id.toString()).name : "Unknown") }}
                                    </b-col>
                                    <b-col v-else cols="3">
                                        {{ (service.name ? service.name : "Unknown") }} <b-badge pill variant="danger">Deleted</b-badge>
                                    </b-col>
                                    <b-col cols="1">
                                        {{ service.hours }}
                                    </b-col>
                                    <b-col cols="1">
                                        ${{ service.amount.toFixed(4) }}
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <hr>
                                    </b-col>
                                </b-row>
                            </span>
                            <span v-if="lastPayment.disks && lastPayment.disks.length != 0">
                                <b-row>
                                    <br>
                                </b-row>
                                <b-row>
                                    <b-col cols="2">
                                        <h6>Disk ID</h6>
                                    </b-col>
                                    <b-col cols="3">
                                        <h6>Name</h6>
                                    </b-col>
                                    <b-col cols="1">
                                        <h6>Hours</h6>
                                    </b-col>
                                    <b-col cols="1">
                                        <h6>Price</h6>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <hr>
                                    </b-col>
                                </b-row>
                                <span v-for="(disk, index) of lastPayment.disks" v-bind:key="index">
                                    <b-row>
                                        <b-col cols="2">
                                            {{ disk.id }}
                                        </b-col>
                                        <b-col cols="3">
                                            {{ disk.note || (disks.find(disc => disc._id.toString() == disk.id.toString()) ? disks.find(disc => disc._id.toString() == disk.id.toString()).name : "Deleted Disk") }}
                                        </b-col>
                                        <b-col cols="1">
                                            {{ disk.hours }}
                                        </b-col>
                                        <b-col cols="1">
                                            ${{ disk.amount.toFixed(4) }}
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <hr>
                                        </b-col>
                                    </b-row>
                                </span>
                            </span>
                            <b-row>
                                <br>
                            </b-row>
                            <b-row>
                                <b-col cols="3">
                                    <h6>Total</h6>
                                    <span v-if="usedBalance">
                                        ${{ usedBalance.toFixed(4) }}
                                    </span>
                                    <span v-else>
                                        $0.0000
                                    </span>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                    <!--<b-tab title="Discord Management">
                        <div style="border: 0.5px solid #DDDEE1; background-color: #FFFFFF; border-radius: 4px; padding: 20px;">
                            <b-row>
                                <b-col cols="4">
                                    <h5>Stacket's official Discord bot</h5>
                                    <p>You can utilize our official discord to display player count statistics or use our commands to control your service.</p>
                                    <p>The bot comes along with a highly customizable permission system to insure your services are kept safe.</p>
                                </b-col>
                                <b-col cols="2">
                                </b-col>
                                <b-col cols="4">
                                    <h5>
                                        Getting started
                                    </h5>
                                    <p>
                                        To get started click <a href="#">here</a> to invite the bot to your discord.
                                    </p>
                                    <p>
                                        Once invited, click <a href="#">here</a> to generate a one-time token which can be entered by using <code>!token %code%</code> in any text channels.
                                    </p>
                                </b-col>
                            </b-row>
                        </div>
                        <span v-if="profile.discordguilds">
                            <div v-for="(guild, index) of profile.discordguilds" v-bind:key="index" style="border: 0.5px solid #DDDEE1; background-color: #FFFFFF; border-radius: 4px; padding: 20px;" class="mt-3">
                                <b-row>

                                    <b-col cols="4">
                                        <h5>Discord Guild ID: {{ guild.id }}</h5>
                                        <p>Due to Discord's rate limits, we can't display guild name or image-</p>
                                    </b-col>
                                    <b-col cols="2">
                                        
                                    </b-col>
                                    <b-col class="mt-2" cols="5">
                                        <h5>
                                            Permissions
                                        </h5>
                                        <h6>
                                            Power
                                        </h6>
                                        <b-form-group>
                                            <b-form-checkbox-group v-model="profile.discordguilds[index].permissions">
                                              <b-form-checkbox value="service.power.start">Start</b-form-checkbox>
                                              <b-form-checkbox value="service.power.stop">Stop</b-form-checkbox>
                                              <b-form-checkbox value="service.power.kill">Kill</b-form-checkbox>
                                              <b-form-checkbox value="service.power.hibernate">Hibernate</b-form-checkbox>
                                              <b-form-checkbox value="service.power.activate">Activate</b-form-checkbox>
                                            </b-form-checkbox-group>
                                        </b-form-group>
                                        <h6>
                                            Console
                                        </h6>
                                        <b-form-group>
                                            <b-form-checkbox-group v-model="profile.discordguilds[index].permissions">
                                              <b-form-checkbox value="service.console.update">Update</b-form-checkbox>
                                              <b-form-checkbox value="service.control.command">Send Commands</b-form-checkbox>
                                            </b-form-checkbox-group>
                                        </b-form-group>
                                        <h6>
                                            Files
                                        </h6>
                                        <b-form-group>
                                            <b-form-checkbox-group v-model="profile.discordguilds[index].permissions">
                                              <b-form-checkbox value="service.files.read">Read</b-form-checkbox>
                                              <b-form-checkbox value="service.files.write">Write</b-form-checkbox>
                                            </b-form-checkbox-group>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </div>
                        </span>
                    </b-tab>-->
                </b-tabs>
            </b-col>
        </b-row>
    </b-container>
</template>

<style lang="scss">
    .billingButton {
        display: inline-block;
        text-align: center;

        margin: 10px;
        width: 140px; 
        height: 60px; 
        border-radius: 3px; 
        background-color: white;
        padding: 12.5px;
        h5 {
            display: inline-block;
            margin-left: 15px;
        }
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border: 2px solid white;
    }

    .selectedBillingButton {
        background-color: #4886FD;
        border-color: #4886FD;
        color: white;
    }

    .billingButton:hover {
        cursor: pointer;
        box-shadow: 0px 0px 10px rgb(106, 158, 255);
    }
</style>

<script>
    import {loadStripe} from '@stripe/stripe-js';

    export default {
        name: "settings",
        components: {
            
        },
        props: ["profile", "disks", "services", "lastPayment", "usedBalance"],
        data: () => {
            return {
                stripe: null,
                token: "",
                newProfile: {
                    fullname: null,
                    email: null,
                    company: null,
                },
                billing: {
                    method: "manual",
                    amount: 5
                },
                //usedBalance: 0,
                tabIndex: 0,
                tabs: ["#account", "#billing", "#invoices"],
                requestingU2F: false
            }
        },
        async mounted() {
            this.token = localStorage.getItem("user-token");
            if(localStorage.getItem("user-token")){
                const token = localStorage.getItem("user-token");   
            }
            this.getProfile();

            this.tabIndex = this.tabs.findIndex(tab => tab === this.$route.hash);

            this.stripe = await loadStripe('pk_live_DDmYHSgThTLYwhMKBs3bJ4JB00FTSLyYKr');
        },
        methods: {
            getProfile() {
                this.$http.get(this.StacketConfig.api.profile + "/", {headers: {"authorization": this.token}}).then(async (response) => {
                    if(response.data.error){
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error
                        })
                    }
                    this.newProfile = response.data;
                    this.profile = response.data;
                });
            },
            removeu2f(){
                this.$http.delete(this.StacketConfig.api.profile + "/u2f", {headers: {"authorization": this.token}}).then( async response2 => {
                    if(response2.data.error) return alert(response2.data.error);
                    this.getProfile();
                    alert("U2F Authentication disabled!");
                }).catch(e => {
                    console.log(e);
                    if(e.data && e.data.error) return alert(e.data.error);
                    alert("Something went wrong!");
                })
            },
            setupu2f(){
                if(window.u2f && window.u2f.register){
                    this.requestingU2F = true;
                    this.$http.get(this.StacketConfig.api.profile + "/u2f", {headers: {"authorization": this.token}}).then( async response => {
                        window.u2f.register(response.data.appId, [response.data], [], async result => {
                            this.$http.post(this.StacketConfig.api.profile + "/u2f", {registerResponse: result}, {headers: {"authorization": this.token}}).then( async response2 => {
                                if(response2.data.error) return alert(response2.data.error);
                                this.getProfile();
                                alert(response2.data.message);
                            }).catch(e => {
                                console.log(e);
                                if(e.data && e.data.error) return alert(e.data.error);
                                alert("Something went wrong!");
                            })
                        })
                    }).catch(e => {
                        console.log(e)
                        if(e.data && e.data.error) return alert(e.data.error);

                        alert("Something went wrong!")
                    })
                } else {
                    alert("U2F not supported by this browser");
                }
            },
            purchase() {
                this.$http.post(this.StacketConfig.api.payment + "/", { amount: this.billing.amount}, {headers: {"authorization": this.token}}).then((response) => {
                    if(response.data.error){
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error
                        }) 
                    }
                    this.stripe.redirectToCheckout({
                        sessionId: response.data.id
                    }).then(function (result) {
                        if(result.error){
                            return this.$notify({
                                group: "notifications",
                                type: 'error',
                                title: "Payment failed!",
                                text: result.error.message
                            }) 
                        }
                    }).catch(err => {
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "Payment failed!",
                            text: err.message || err
                        }) 
                    })
                }).catch(err => {
                    var er = "Unable to create transaction session!";
                    if(err.data && err.data.error) {
                        er = err.data.error;
                    }
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: er
                    }) 
                })
            },
            downloadGDPR(){
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Downloading GDPR data",
                    text: "Pleae wait..."
                })
                this.$http.get(this.StacketConfig.api.profile + "/gdpr", {headers: {"authorization": this.token}}).then((response) => {
                        if(response.data.error){
                            return this.$notify({
                                group: "notifications",
                                type: 'error',
                                title: "API ERROR",
                                text: response.data.error
                            })
                        }
                        var element = document.createElement('a');
                        element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(response.data)));
                        element.setAttribute('download', response.data.profile._id + ".json");

                        element.style.display = 'none';
                        document.body.appendChild(element);

                        element.click();

                        document.body.removeChild(element);
                        return this.$notify({
                            group: "notifications",
                            type: 'success',
                            title: "Downloaded",
                            text: "Successfully downloaded GDPR data!"
                        })
                    }).catch(err => {
                        this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: "Unable to download GDPR data!"
                        })
                    }); 
            },
            updateProfile() {
                if(this.profile.fullname != this.newProfile.fullname || this.profile.email != this.newProfile.email || this.profile.company != this.newProfile.company){
                    this.$http.post(this.StacketConfig.api.profile + "/", {"fullname": this.newProfile.fullname, "email": this.newProfile.email, "company": this.newProfile.company}, {headers: {"authorization": this.token}}).then((response) => {
                        if(response.data.error){
                            return this.$notify({
                                group: "notifications",
                                type: 'error',
                                title: "API ERROR",
                                text: response.data.error
                            })
                        }
                        this.profile.fullname = this.newProfile.fullname.toString();
                        this.profile.email = this.newProfile.email.toString();
                        this.profile.company = this.newProfile.company.toString();
                        return this.$notify({
                                group: "notifications",
                                type: 'success',
                                title: "Updated",
                                text: "Successfully updated profile changes!"
                            })
                    }).catch(err => {
                        this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: "Unable to save profile changes!"
                        })
                    }); 
                }
            },
            getMonthName(date){
                const monthNames = ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"];
                return monthNames[date.getMonth()];
            }
        }
    }
</script>