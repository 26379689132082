<template>
    <b-card-group deck v-if="network.team">
        <b-card body-tag="div" header-bg-variant="white" header-tag="header" style="border-color: #eff0f5;" class="serviceCard shadow-xl">
            <template v-slot:header>
                <router-link :to="'/dashboard/networks/'+network._id" style="text-decoration: none;">
                    <h6 class="mb-0" style="color: #777676;">
                        <span style="color: #777676;">{{ network.name }}</span>
                    </h6>
                </router-link>
            </template>
            <b-card-body v-if="network.team.groups && network.team.groups.length != 0">
                <b-row style="margin-bottom: 5px;">
                    <b-col cols="4">
                        <center>
                            <img v-bind:src="network.team.image" style="width: 50px;" v-b-tooltip.hover v-bind:title="network.team.name">
                        </center>
                    </b-col>
                    <b-col cols="1">
                        <center style="height: 100%;">
                            <div style="height: 100%; border: #e2e2e4 solid 0.5px;"></div>
                        </center>
                    </b-col>
                    <b-col style="padding-top: 10px;">
                        <span v-html="convertName(network.team.groups[0].name || '')" v-if="network.team.groups[0]" v-b-tooltip.hover v-bind:title="network.team.groups[0].name" class="align-middle shadow-sm" style="text-transform: uppercase; text-align: center; background-color: #eaf8eb; font-weight: 500; font-size: 12px; color: #2ead42; padding: 7px; padding-top: 10px; border-radius: 100%; width: 35px; height: 35px;">
                            
                        </span>
                        <span v-html="convertName(network.team.groups[1].name || '')" v-if="network.team.groups[1]" v-b-tooltip.hover v-bind:title="network.team.groups[1].name" class="align-middle shadow-sm" style="text-transform: uppercase; text-align center; background-color: #f0f3fa; font-weight: 500; font-size: 12px; color: #477ed9; padding: 7px; padding-top: 10px; border-radius: 100%; width: 35px; height: 35px; margin-left: 10px;">
                            
                        </span>
                        <span v-html="'+'+(network.team.groups.length - 2)" v-if="network.team.groups[2]" class="align-middle shadow-sm" style="text-transform: uppercase; text-align: center; background-color: #ffeff1; font-weight: 500; font-size: 13px; color: #f13862; padding: 9px; padding-top: 9px; border-radius: 100%; width: 35px; height: 35px; margin-left: 10px;">
                            
                        </span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4">
                        <center>
                            <h6>
                                Team
                            </h6>
                        </center>
                    </b-col>
                    <b-col>
                        <center>
                            <h6>
                                Groups
                            </h6>
                        </center>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body v-else="">
                <b-row>
                    <b-col>
                        <b-row style="margin-bottom: 5px;">
                            <b-col>
                                <center>
                                    <img v-bind:src="network.team.image" style="width: 50px; border-radius: 100%;"  v-b-tooltip.hover v-bind:title="network.team.name">
                                </center>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <center>
                                    <h6>
                                        Owner
                                    </h6>
                                </center>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                <b-row>
                    <b-col style="margin-left: -15px;">
                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                            IP Address
                        </h6>
                    </b-col>
                    <b-col>
                        <h6 style="text-align: right; font-size: 13px">
                            {{ network.ip }}
                        </h6>
                    </b-col>
                </b-row>
            </b-card-footer>
            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -25px;">
                <b-row>
                    <b-col style="margin-left: -15px;">
                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                            Network Type
                        </h6>
                    </b-col>
                    <b-col>
                        <h6 style="text-align: right; font-size: 13px">
                            {{ network.type == "ipv4" ? "IPv4" : "IPv6" }}
                        </h6>
                    </b-col>
                </b-row>
            </b-card-footer>
        </b-card>
    </b-card-group>
    <b-card-group deck v-else class="mt-3">
        <b-card body-tag="div" header-bg-variant="white" header-tag="header" style="border-color: #eff0f5;" class="serviceCard shadow-xl">
            <template v-slot:header>
                <router-link :to="'/dashboard/networks/'+network._id" style="text-decoration: none;">
                    <h6 class="mb-0" style="color: #777676;">
                        <span style="color: #777676;">{{ network.name }}</span>
                    </h6>
                </router-link>
            </template>
            <b-card-body v-if="network.subusers && network.subusers.length != 0">
                <b-row>
                    <b-col>
                        <b-row style="margin-bottom: 5px;">
                            <b-col cols="4">
                                <center>
                                    <img v-bind:src="network.owner.image" style="width: 50px; border-radius: 100%;"  v-b-tooltip.hover v-bind:title="network.owner.fullname">
                                </center>
                            </b-col>
                            <b-col cols="1">
                                <center style="height: 100%;">
                                    <div style="height: 100%; border: #e2e2e4 solid 0.5px; width: 0.5px;"></div>
                                </center>
                            </b-col>
                            <b-col style="padding-top: 10px;">
                                <span v-html="convertName(network.subusers[0].fullname || '')" v-if="network.subusers[0]" v-b-tooltip.hover v-bind:title="network.subusers[0].fullname" class="align-middle shadow-sm" style="text-transform: uppercase; text-align: center; background-color: #eaf8eb; font-weight: 500; font-size: 12px; color: #2ead42; padding: 7px; padding-top: 10px; border-radius: 100%; width: 35px; height: 35px;">
                            
                                </span>
                                <span v-html="convertName(network.subusers[1].fullname || '')" v-if="network.subusers[1]" v-b-tooltip.hover v-bind:title="network.subusers[1].fullname" class="align-middle shadow-sm" style="text-transform: uppercase; text-align: center; background-color: #f0f3fa; font-weight: 500; font-size: 12px; color: #477ed9; padding: 7px; padding-top: 10px; border-radius: 100%; width: 35px; height: 35px; margin-left: 10px;">
                                    
                                </span>
                                <span v-html="'+'+(network.subusers.length - 2)" v-if="network.subusers[2]" class="align-middle shadow-sm" style="text-transform: uppercase; text-align: center; background-color: #ffeff1; font-weight: 500; font-size: 13px; color: #f13862; padding: 9px; padding-top: 9px; border-radius: 100%; width: 35px; height: 35px; margin-left: 10px;">
                                    
                                </span>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4">
                                <center>
                                    <h6>
                                        Owner
                                    </h6>
                                </center>
                            </b-col>
                            <b-col>
                                <center>
                                    <h6>
                                        Shared People
                                    </h6>
                                </center>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body v-else="">
                <b-row>
                    <b-col>
                        <b-row style="margin-bottom: 5px;">
                            <b-col>
                                <center>
                                    <img v-bind:src="network.owner.image" style="width: 50px; border-radius: 100%;"  v-b-tooltip.hover v-bind:title="network.owner.fullname">
                                </center>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <center>
                                    <h6>
                                        Owner
                                    </h6>
                                </center>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                <b-row>
                    <b-col style="margin-left: -15px;">
                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                            IP Address
                        </h6>
                    </b-col>
                    <b-col>
                        <h6 style="text-align: right; font-size: 13px">
                            {{ network.ip }}
                        </h6>
                    </b-col>
                </b-row>
            </b-card-footer>
            <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                <b-row>
                    <b-col style="margin-left: -15px;">
                        <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                            Network Type
                        </h6>
                    </b-col>
                    <b-col>
                        <h6 style="text-align: right; font-size: 13px">
                            {{ network.type == "ipv4" ? "IPv4" : "IPv6" }}
                        </h6>
                    </b-col>
                </b-row>
            </b-card-footer>
        </b-card>
    </b-card-group>
</template>

<style lang="scss" scoped>

</style>

<script>
    export default {
        name: "networkCard",
        components: {
            
        },
        data: () => {
            return {
                token: "",
                products: {},
                items: []
            }
        },
        props: ['network'],
        mounted() {
            this.token = localStorage.getItem("user-token");
            if(localStorage.getItem("user-token")){
                const token = localStorage.getItem("user-token");

                this.products = this.$parent.products;

                this.network.services.forEach((service, i) => {
                    this.items[i] = {};
                    this.items[i].name = service.name;
                    this.items[i].type = service.type;
                    this.items[i].version = service.version;
                    this.items[i].ip = service.networks.find(net => net.id == this.network._id).ipv4 || service.networks.find(net => net.id == this.network._id).ipv6;
                });
            }
        },
        methods: {
            convertName(name) {
                var fullname = name+"";
                var split = fullname.split(" ");
                if(split.length == 1){
                    return split[0].charAt(0) + split[0].charAt(1);
                } else {
                    var firstName = split[0];
                    var lastName = split[split.length-1];

                    return firstName.charAt(0) + lastName.charAt(0);
                }

            },
        }
    }
</script>