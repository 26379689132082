<template>
    <b-container fluid>
        <b-row class="mt-4">
            <b-col>
                Id
            </b-col>
            <b-col>
                Public
            </b-col>
            <b-col>
                Target
            </b-col>
            <b-col>
                IP Address
            </b-col>
            <b-col>
                UDP
            </b-col>
            <b-col>
                State
            </b-col>
            <b-col></b-col>
        </b-row>
        <b-row class="mt-4" v-for="(allocation, index) of service.service.allocations" v-bind:key="index">
            <b-col>
                {{ allocation.id }}
                <b-badge variant="primary" v-if="allocation.default && allocation.default == true">Default</b-badge>
            </b-col>
            <b-col>
                <b-input-group>
                    <b-form-input v-if="service.service.allocations[index].ip" type="number" min="0" max="65535" v-model="service.service.allocations[index].public"></b-form-input>
                    <b-form-input v-else disabled type="number" min="0" max="65535" v-model="service.service.allocations[index].public"></b-form-input>
                </b-input-group>
            </b-col>
            <b-col>
                <b-input-group>
                    <b-form-input type="number" min="0" max="65535" v-model="service.service.allocations[index].target"></b-form-input>
                </b-input-group>
            </b-col>
            <b-col>
                <b-input-group>
                    <b-form-input v-if="service.service.allocations[index].default == true" disabled type="text" v-model="service.service.allocations[index].ip"></b-form-input>
                    <b-form-input v-else type="text" v-model="service.service.allocations[index].ip"></b-form-input>
                </b-input-group>
            </b-col>
            <b-col>
                <b-input-group>
                    <b-form-select :options="optionsUdp" v-model="service.service.allocations[index].udp"></b-form-select>
                </b-input-group>
            </b-col>
            <b-col>
                <b-input-group>
                    <b-form-select :options="optionsDisabled" v-model="service.service.allocations[index].disabled"></b-form-select>
                </b-input-group>
            </b-col>
            <b-col>
                <b-button variant="primary" @click="saveAllocation(allocation.id)" style="margin-right: 10px;">Save</b-button>
                <b-button variant="danger" @click="deleteAllocation(allocation.id)">Delete</b-button>
            </b-col>
        </b-row>
        <b-row style="margin-top: 50px;">
            <b-col>
                <h5>
                    New Allocation
                </h5>
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col>
                Public
            </b-col>
            <b-col>
                Target
            </b-col>
            <b-col>
                IP Address
            </b-col>
            <b-col>
                UDP
            </b-col>
            <b-col>
                State
            </b-col>
            <b-col></b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col>
                <b-input-group>
                    <b-form-input v-if="Newallocation.ip == ''" disabled type="number" min="0" max="65535"></b-form-input>
                    <b-form-input v-else type="number" min="0" max="65535" v-model="Newallocation.public"></b-form-input>
                </b-input-group>
            </b-col>
            <b-col>
                <b-input-group>
                    <b-form-input type="number" min="0" max="65535" v-model="Newallocation.target"></b-form-input>
                </b-input-group>
            </b-col>
            <b-col>
                <b-input-group>
                    <b-form-input type="text" v-model="Newallocation.ip"></b-form-input>
                </b-input-group>
            </b-col>
            <b-col>
                <b-input-group>
                    <b-form-select :options="optionsDisabled" v-model="Newallocation.disabled"></b-form-select>
                </b-input-group>
            </b-col>
            <b-col>
                <b-input-group>
                    <b-form-select :options="optionsUdp" v-model="Newallocation.udp"></b-form-select>
                </b-input-group>
            </b-col>
            <b-col>
                <b-button variant="primary" @click="newAllocation()" style="margin-right: 10px;">Add</b-button>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>

</script>
<style>

</style>
<script>

export default {
    name: "Allocations",
    components: {
        
    },
    props: [
        "service"
    ],
    data: () => {
        return {
            token: "",
            //service: {},
            Newallocation: {
                public: 25565,
                target: 25565,
                ip: "",
                disabled: false,
                udp: false,
            },
            optionsDisabled: [
                {
                    value: true,
                    text: "Disabled"
                }, {
                    value: false,
                    text: "Enabled"
                } 
            ],
            optionsUdp: [
                {
                    value: false,
                    text: "Disabled"
                }, {
                    value: true,
                    text: "Enabled"
                }    
            ]
        }
    },
    mounted() {
        this.token = localStorage.getItem("user-token");
       //this.service = this.$parent.service;
    },
    methods: {
        newAllocation(){
            this.Newallocation.public = parseInt(this.Newallocation.public);
            this.Newallocation.target = parseInt(this.Newallocation.target);
            if(this.Newallocation.ip == ""){
                delete this.Newallocation.public;
                delete this.Newallocation.ip
            }

            this.$http.post(this.StacketConfig.api.services + "/" + this.$route.params.page, {allocation: this.Newallocation}, {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.service.service.allocations.push(response.data);
                this.$notify({
                    group: "notifications",
                    type: 'succes',
                    title: "Success",
                    text: "Allocation added!"
                })
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not add allocation!"
                })
            });
        },
        saveAllocation(allocationId){
            var allo = this.service.service.allocations.find(all => all.id.toString() == allocationId.toString());
            console.log(allo)
            allo.public = parseInt(allo.public);
            allo.target = parseInt(allo.target);
            if(allo.ip == ""){
                allo.ip = null;
            }

            this.$http.post(this.StacketConfig.api.services + "/" + this.$route.params.page, {allocation: allo}, {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.$notify({
                    group: "notifications",
                    type: 'succes',
                    title: "Success",
                    text: "Allocation saved!"
                })
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: err.data.error || "Could not save allocation!"
                })
            });
        },
        deleteAllocation(allocationId){
            var allo = this.service.service.allocations.find(all => all.id.toString() == allocationId.toString());

            this.$http.post(this.StacketConfig.api.services + "/" + this.$route.params.page, {allocation: {id: allo.id, delete: true}}, {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.$notify({
                    group: "notifications",
                    type: 'succes',
                    title: "Success",
                    text: "Allocation deleted!"
                })
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not delete allocation!"
                })
            });
        },
    }
}  
</script>