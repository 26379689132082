<template>
    <b-container fluid>
        <b-row class="mt-4">
            <b-col>
                <b-tabs>
                    <b-tab title="General" active>
                        <div style="border: 0.5px solid #DDDEE1; background-color: #FFFFFF; border-radius: 4px; padding: 20px;" class="mt-3">
                            <b-row style="margin-bottom: 25px;">
                                <b-col cols="3">
                                    <h5>Service Settings</h5>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="2">
                                    <h6>Setting</h6>
                                </b-col>
                                <b-col cols="2">
                                    <h6>Value</h6>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <hr>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="2">
                                    Name
                                </b-col>
                                <b-col>
                                    <b-input-group size="sm">
                                        <b-form-input v-model="service.service.name"></b-form-input>
                                    </b-input-group>
                                </b-col>
                                <b-col cols="1">
                                    <b-button variant="success" @click="saveSetting('name')">Save</b-button>
                                </b-col>
                            </b-row><b-row>
                                <b-col>
                                    <hr>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="2">
                                    Restart on crash
                                </b-col>
                                <b-col>
                                    <b-input-group size="sm">
                                        <b-form-select v-model="service.service.settings.autorestart" :options="autorestartsettings"></b-form-select>
                                    </b-input-group>
                                </b-col>
                                <b-col cols="1">
                                    <b-button variant="success" @click="saveSetting('autorestart')">Save</b-button>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <hr>
                                </b-col>
                            </b-row>
                            <span v-if="service.service.type == 'minecraft'">
                                <b-row>
                                    <b-col cols="2">
                                        Java Version
                                    </b-col>
                                    <b-col>
                                        <b-input-group size="sm">
                                            <b-form-select v-model="service.service.settings.firmware" :options="javasettings"></b-form-select>
                                        </b-input-group>
                                    </b-col>
                                    <b-col cols="1">
                                        <b-button variant="success" @click="saveSetting('firmware')">Save</b-button>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <hr>
                                    </b-col>
                                </b-row>
                            </span>
                        </div>
                        <b-row>
                            <b-col cols="3">
                                <b-button variant="danger" @click="deleteService()">Delete Service</b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="3">
                                <p>
                                    This can not be undone!
                                </p>
                            </b-col>
                        </b-row>
                        <b-alert variant="warning" style="margin-top: 25px;" show>More coming soon!</b-alert>
                    </b-tab>
                    <b-tab title="Advanced">
                        <div style="border: 0.5px solid #DDDEE1; background-color: #FFFFFF; border-radius: 4px; padding: 20px;" class="mt-3">
                            <b-row style="margin-bottom: 25px;">
                                <b-col cols="3">
                                    <h5>Environment Variables</h5>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="2">
                                    <h6>Key</h6>
                                </b-col>
                                <b-col cols="2">
                                    <h6>Value</h6>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <hr>
                                </b-col>
                            </b-row>
                            <span v-for="(value, index) in service.service.environment" v-bind:key="index">
                                <b-row>
                                    <b-col cols="2">
                                        {{ index }}
                                    </b-col>
                                    <b-col>
                                        <b-input-group size="sm">
                                            <b-form-input v-model="service.service.environment[index]"></b-form-input>
                                        </b-input-group>
                                    </b-col>
                                    <b-col cols="1">
                                        <b-button variant="success" @click="save(index)">Save</b-button>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <hr>
                                    </b-col>
                                </b-row>
                            </span>
                        </div>
                    </b-tab>
                </b-tabs>
            </b-col>
        </b-row>
    </b-container>
</template>

<style lang="scss">

</style>

<script>

export default {
    name: "Service Settings",
    components: {
        
    },
    props: [
        "service"
    ],
    data: () => {
        return {
            token: "",
            autorestartsettings: [
                {
                    value: true,
                    text: "Yes"
                },
                {
                    value: false,
                    text: "No"
                }
            ],
            javasettings: [
                {
                    value: 7,
                    text: "Java 7"
                },
                {
                    value: 8,
                    text: "Java 8"
                },
                {
                    value: 9,
                    text: "Java 9"
                },
                {
                    value: 10,
                    text: "Java 10"
                },
                {
                    value: 11,
                    text: "Java 11"
                }
            ]
        }
    },
    mounted() {
        this.token = localStorage.getItem("user-token");
    },
    methods: {
        deleteService(){
            this.$notify({
                group: "notifications",
                type: 'warning',
                title: "Deleting Service..",
                text: `Please wait`
            });
            this.$http.delete(this.StacketConfig.api.services + "/" + this.$route.params.page, {headers: {"authorization": this.token}}).then(async response => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Deleted",
                    text: `Successfully deleted service`
                });
                this.$router.replace("/dashboard");
            }).catch(err => {
                if(err.data && err.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "Error",
                        text: err.data.error
                    })
                }
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: `Could not delete service!`
                })
            });
        },
        saveSetting(index) {
            let update = {};
            if(index == "name"){
                update[index] = this.service.service[index];
            } else {
                update["settings"] = {};
                update["settings"][index] = this.service.service.settings[index];
            }
            this.$http.post(this.StacketConfig.api.services + "/" + this.$route.params.page, update, {headers: {"authorization": this.token}}).then(async response => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Saved",
                    text: `${index.replace(index.charAt(0), index.charAt(0).toUpperCase())} saved!`
                });
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: `Could not save ${index.replace(index.charAt(0), index.charAt(0).toUpperCase())}!`
                })
            });
        },
        save(index) {
            var value = this.service.service.environment[index];
            var env = {
                "key": index,
                "value": value
            };
            this.$http.post(this.StacketConfig.api.services + "/" + this.$route.params.page, {environment: env}, {headers: {"authorization": this.token}}).then(async response => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Saved",
                    text: "Enviroment variable saved!"
                })
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not save enviroment variable!"
                })
            });
        }
    }
}  
</script>