<template>
    <b-row style="background-color: #e8ecfa;">
        <b-col>
            <b-row class="justify-content-center">
                <h2 class="mt-5">{{ Lang.howwestackup.title }}</h2>
            </b-row>
            <b-row class="mt-3" style="text-align: center;">
                <b-col></b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;"></b-col>
                <b-col style="background-color: #dbe3ff; padding: 10px; border-bottom: lightgray 1px solid;">
                    <h5><strong>{{ Lang.howwestackup.companies[0].name }}</strong></h5>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <h5><strong>{{ Lang.howwestackup.companies[1].name }}</strong></h5>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <h5><strong>{{ Lang.howwestackup.companies[2].name }}</strong></h5>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <h5><strong>{{ Lang.howwestackup.companies[3].name }}</strong></h5>
                </b-col>
                <b-col></b-col>
            </b-row>
            <b-row style="text-align: center;">
                <b-col></b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <h5>
                        <strong>
                            {{ Lang.howwestackup.categories.memory }}
                        </strong>
                    </h5>
                </b-col>
                <b-col style="background-color: #dbe3ff; padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">{{ Lang.howwestackup.companies[0].memory }}<small>/gb</small></span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">{{ Lang.howwestackup.companies[1].memory }}<small>/gb</small></span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">{{ Lang.howwestackup.companies[2].memory }}<small>/gb</small></span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">{{ Lang.howwestackup.companies[3].memory }}<small>/gb</small></span>
                </b-col>
                <b-col></b-col>
            </b-row>
            <b-row style="text-align: center;">
                <b-col></b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <h5>
                        <strong>
                            {{ Lang.howwestackup.categories.cpu }}
                        </strong>
                    </h5>
                </b-col>
                <b-col style="background-color: #dbe3ff; padding: 10px; border-bottom: lightgray 1px solid; cursor: pointer;">
                    <span style="font-size: 1.25rem;" v-b-tooltip.hover v-bind:title="Lang.howwestackup.cpurating +': ' + Lang.howwestackup.companies[0].cpu.score">{{ Lang.howwestackup.companies[0].cpu.name }}</span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid; cursor: pointer;">
                    <span style="font-size: 1.25rem;" v-b-tooltip.hover v-bind:title="Lang.howwestackup.cpurating +': ' + Lang.howwestackup.companies[1].cpu.score">{{ Lang.howwestackup.companies[1].cpu.name }}</span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid; cursor: pointer;">
                    <span style="font-size: 1.25rem;" v-b-tooltip.hover v-bind:title="Lang.howwestackup.cpurating +': ' + Lang.howwestackup.companies[2].cpu.score">{{ Lang.howwestackup.companies[2].cpu.name }}</span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid; cursor: pointer;">
                    <span style="font-size: 1.25rem;" v-b-tooltip.hover v-bind:title="Lang.howwestackup.cpurating +': ' + Lang.howwestackup.companies[3].cpu.score">{{ Lang.howwestackup.companies[3].cpu.name }}</span>
                </b-col>
                <b-col></b-col>
            </b-row>
            <b-row style="text-align: center;">
                <b-col></b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <h5>
                        <strong>
                            {{ Lang.howwestackup.categories.storage }}
                        </strong>
                    </h5>
                </b-col>
                <b-col style="background-color: #dbe3ff; padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">{{ Lang.howwestackup.companies[0].storage }}</span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">{{ Lang.howwestackup.companies[1].storage }}</span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">{{ Lang.howwestackup.companies[2].storage }}</span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">{{ Lang.howwestackup.companies[3].storage }}</span>
                </b-col>
                <b-col></b-col>
            </b-row>
            <b-row style="text-align: center;">
                <b-col></b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <h5>
                        <strong>
                            {{ Lang.howwestackup.categories.ddos }}
                        </strong>
                    </h5>
                </b-col>
                <b-col style="background-color: #dbe3ff; padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;" v-html="Lang.howwestackup.companies[0].ddos"></span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;" v-html="Lang.howwestackup.companies[1].ddos"></span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;" v-html="Lang.howwestackup.companies[2].ddos"></span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;" v-html="Lang.howwestackup.companies[3].ddos"></span>
                </b-col>
                <b-col></b-col>
            </b-row>
            <b-row style="text-align: center;">
                <b-col></b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <h5>
                        <strong>
                            {{ Lang.howwestackup.categories.instantsetup }}
                        </strong>
                    </h5>
                </b-col>
                <b-col style="background-color: #dbe3ff; padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[0].instantsetup == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[1].instantsetup == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[2].instantsetup == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[3].instantsetup == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col></b-col>
            </b-row>
            <b-row style="text-align: center;">
                <b-col></b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <h5>
                        <strong>
                            {{ Lang.howwestackup.categories.ftp }}
                        </strong>
                    </h5>
                </b-col>
                <b-col style="background-color: #dbe3ff; padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[0].ftp == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[1].ftp == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[2].ftp == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[3].ftp == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col></b-col>
            </b-row>
            <b-row style="text-align: center;">
                <b-col></b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <h5>
                        <strong>
                            {{ Lang.howwestackup.categories.payasyougo }}
                        </strong>
                    </h5>
                </b-col>
                <b-col style="background-color: #dbe3ff; padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[0].payasyougo == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[1].payasyougo == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[2].payasyougo == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[3].payasyougo == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col></b-col>
            </b-row>
            <b-row style="text-align: center;">
                <b-col></b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <h5>
                        <strong>
                            {{ Lang.howwestackup.categories.api }}
                        </strong>
                    </h5>
                </b-col>
                <b-col style="background-color: #dbe3ff; padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[0].api == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[1].api == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[2].api == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[3].api == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col></b-col>
            </b-row>
            <b-row style="text-align: center;">
                <b-col></b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <h5 v-b-tooltip.hover v-bind:title="Lang.howwestackup.categories.custompanel.note">
                        <strong>
                            {{ Lang.howwestackup.categories.custompanel.title }}
                        </strong>
                    </h5>
                </b-col>
                <b-col style="background-color: #dbe3ff; padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[0].custompanel == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[1].custompanel == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[2].custompanel == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[3].custompanel == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col></b-col>
            </b-row>
            <b-row style="text-align: center;">
                <b-col></b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <h5>
                        <strong>
                            {{ Lang.howwestackup.categories.serviceimage }}
                        </strong>
                    </h5>
                </b-col>
                <b-col style="background-color: #dbe3ff; padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[0].serviceimage == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[1].serviceimage == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[2].serviceimage == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[3].serviceimage == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col></b-col>
            </b-row>
            <b-row style="text-align: center;" class="mb-2">
                <b-col></b-col>
                <b-col style="padding: 10px;">
                    <h5>
                        <strong>
                            {{ Lang.howwestackup.categories.privatenetworking }}
                        </strong>
                    </h5>
                </b-col>
                <b-col style="background-color: #dbe3ff; padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[0].privatenetworking == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[1].privatenetworking == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[2].privatenetworking == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col style="padding: 10px; border-bottom: lightgray 1px solid;">
                    <span style="font-size: 1.25rem;">
                        <i class="fas fa-check" v-if="Lang.howwestackup.companies[3].privatenetworking == true"></i>
                        <i class="fas fa-times" v-else></i>
                    </span>
                </b-col>
                <b-col></b-col>
            </b-row>
            <b-row class="mb-5 justify-content-center">
                <b-col :cols="5" style="font-size: 0.75rem; text-align: center;">
                    {{ Lang.howwestackup.note }}
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<style lang="scss" scoped>
    .fa-check {
        color: #28a745!important;
    }
    .fa-times {
        color: #dc3545!important;
    }
</style>

<script>
    export default {
        name: "howWeStackUp",
        components: {
            
        },
        data: () => {
            return {
                
            }
        },
        mounted() {
            
        },
        methods: {

        }
    }
</script>