<template>
    <b-container fluid>
        <b-modal id="newDisk" title="Upgrade virtual disk" ok-only centered @ok="updateDisk" v-bind:okTitle="'Order ($' + $parent.getPrice(newDisk.size).toFixed(2) +')'">
            <b-form-group v-bind:label="'Size: ' + newDisk.size + 'gb'">
                <b-form-input id="range-2" v-model="newDisk.size" type="range" min="5" max="1000" step="5"></b-form-input>
            </b-form-group>
        </b-modal>
        <b-modal id="ftp" title="FTP Access Information" okTitle="Close" centered ok-only>
            <b>
                IP Address:
            </b>
            disk.stacket.net
            <br>
            <b>
                Port:
            </b>
            21
            <br>
            <b>
                Username:
            </b>
            {{ disk.disk._id }}
            <br>
            <b>
                Password:
            </b>
            {{ disk.disk.password }}
        </b-modal>
        <b-row style="margin-top: 50px; color: rgb(73, 80, 87)!important;">
            <b-col cols="1"></b-col>
            <b-col class="mt-4" style="min-width: 375px; margin-bottom: 25px;">
                <b-row>
                    <b-col cols="2">
                        <router-link to="/dashboard/disks" style="text-decoration: none;">
                            <h6 style="margin-top: 20px;"><i class="fas fa-chevron-left"></i> disks</h6>
                        </router-link>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4">
                        <h2 v-if="!diskEditing">{{ disk.disk.name }}</h2>
                        <h2 v-else><b-form-input v-model="disk.disk.name" placeholder="Enter disk name" style="font-size: 2rem; font-weight: 500;"></b-form-input></h2>
                    </b-col>
                    <b-col cols="4">
                        <span v-b-tooltip.hover title="Edit" @click="togglediskEditing()" v-if="!diskEditing"><i class="fas fa-edit editIcon mt-2" style="color:lightgray; font-size: 1.5rem;"></i></span>
                        <span v-b-tooltip.hover title="Save" @click="togglediskEditing()" v-if="diskEditing"><i class="fas fa-save saveIcon mt-2" style="color:lightgray; font-size: 1.5rem;"></i></span>
                        <span v-b-tooltip.hover title="Delete" style="text-align: center;" @click="deletedisk()"><i class="fas fa-trash-alt trashIcon" style="margin-left: 15px; color:lightgray; font-size: 1.5rem"></i></span>
                        <span v-b-tooltip.hover title="FTP Access" @click="ftpAccess()" ><i class="fas fa-folder saveIcon mt-2" style="margin-left: 15px; color:lightgray; font-size: 1.5rem;"></i></span>
                    </b-col>
                    <b-col cols="3">
                        <h4>
                            <span v-b-tooltip.hover title="Updates every 10 minutes">Usage</span>
                            <span style="margin-left: 5px; font-size: 13px;">{{ (disk.disk.used / 1000000).toFixed(2) }} MB / {{ disk.disk.size }} GB</span>
                        </h4>
                    </b-col>
                    <b-col cols="1" style="text-align: right;">
                        <i v-b-tooltip.hover title="Upgrade" style="cursor: pointer;" @click="showUpgrade()" class="fas fa-wrench"></i>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="8">
                        <p>{{ disk.disk._id }}</p>
                    </b-col>
                    <b-col cols="4">
                        <b-progress :max="disk.disk.size * 1000000000">
                            <b-progress-bar :value="disk.disk.used"></b-progress-bar>
                          </b-progress>
                    </b-col>
                </b-row>
                <div style="border: 0.5px solid #DDDEE1; background-color: #FFFFFF; border-radius: 4px; padding: 20px; min-height: 400px;">
                    <b-row>
                        <b-col cols="12">
                            <b-form-select v-b-tooltip.hover title="Service" style="float: left; width: 40%; margin-top: 5px; margin-right: 10px; font-size: 17px; font-family: 'Roboto', sans-serif; font-weight: 500;" v-model="connectId">
                                <template v-slot:first>
                                    <b-form-select-option :value="''" disabled>Select a Service</b-form-select-option>
                                </template>
                                <b-form-select-option v-for="service in services" v-bind:key="service._id" :value="service._id">
                                    {{ service.name }}
                                </b-form-select-option>
                            </b-form-select>
                            <b-input-group v-b-tooltip.hover title="Disk Mount Path" style="float: left; width: 20%; margin-top: 5px; margin-right: 10px; font-size: 17px; font-family: 'Roboto', sans-serif; font-weight: 500;">
                                <b-form-input placeholder="Disk Mount Path" v-model="path.disk"></b-form-input>
                            </b-input-group>
                            <b-input-group v-b-tooltip.hover title="Service Mount Path" style="float: left; width: 20%; margin-top: 5px; margin-right: 10px; font-size: 17px; font-family: 'Roboto', sans-serif; font-weight: 500;">
                                <b-form-input placeholder="Service Mount Path" v-model="path.service"></b-form-input>
                            </b-input-group>
                            <b-button disabled v-if="connectId == ''" variant="success" @click="connect(disk.disk._id, connectId)" style="margin-top: 5px;">
                                Mount
                            </b-button>
                            <b-button v-else variant="success" @click="connect(disk.disk._id, connectId)" style="margin-top: 5px;">
                                Mount
                            </b-button>
                        </b-col>
                    </b-row>
                    <b-row style="margin-top: 15px;">
                        <b-col cols="3" style="min-width: 375px; margin-bottom: 25px;" v-for="(service, index) of services.filter(service => service.disks.find(dis => dis.id == disk.disk._id))" v-bind:key="index">
                            <diskServiceCard :service="service" :disk="disk"></diskServiceCard>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col cols="1"></b-col>
        </b-row>
    </b-container>
</template>

<style lang="scss">

</style>

<script>
    import diskServiceCard from "@/components/diskServiceCard.vue";

    export default {
        name: "disk",
        components: {
            diskServiceCard
        },
        data: () => {
            return {
                token: "",
                disk: {},
                connectId: "",
                path: {
                    disk: "/",
                    service: "/disk"
                },
                diskEditing: false,
                newDisk: {
                    size: 5
                }
            }
        },
        props: [
            "teams",
            "disks",
            "services"
        ],
        mounted() {
            this.token = localStorage.getItem("user-token");
            if(localStorage.getItem("user-token")){
                const token = localStorage.getItem("user-token");

                this.getdisk();
            }
        },
        methods: {
            showUpgrade(){
                this.newDisk.size = this.disk.disk.size;
                this.$bvModal.show('newDisk');
            },
            ftpAccess(){
                this.$bvModal.show('ftp');
            },
            updateDisk(){
                var size = this.newDisk.size; //gb
                this.$http.post(this.StacketConfig.api.disks + `/${this.disk.disk._id}`, {"size": size}, {headers: {"authorization": this.token}}).then(response => {
                    if(response.data.error) {
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error
                        });
                    }
                    this.getdisk();
                }).catch(err => {
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not upgrade disk!"
                    });
                });
            },
            getdisk() {
                let loader = this.$loading.show({loader: "bars", color: "#4886fd"});
                this.$http.get(this.StacketConfig.api.disks + "/" + this.$route.params.page2, {headers: {"authorization": this.token}}).then(async (response) => {
                    if(response.data.error){
                        loader.hide();
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error
                        })
                    }
                    this.disk = response.data;
                    loader.hide();
                }).catch(err => {
                    loader.hide();
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not fetch disk!"
                    })
                });
            },
            deletedisk(){
                this.$notify({
                    group: "notifications",
                    type: 'warning',
                    title: "Deleting Disk",
                    text: "Please wait..."
                });
                this.$http.delete(this.StacketConfig.api.disks + `/${this.disk.disk._id}`, {headers: {"authorization": this.token}}).then(response => {
                    if(response.data.error) {
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error
                        });
                    }
                    this.$notify({
                        group: "notifications",
                        type: 'success',
                        title: "Success",
                        text: "Disk Deleted"
                    });
                    this.$router.push('/dashboard/disks');
                    this.$parent.$parent.getDisks();
                }).catch(err => {
                    if(err.data && err.data.error){
                        this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: "Could not delete disk!"
                        });
                    }
                })
            },
            connect(disk, service) {
                console.log("Connecting " + service + ", please wait...");
                this.$http.post(this.StacketConfig.api.disks + `/${disk}/connect/${service}`, {path: this.path}, {headers: {"authorization": this.token}}).then(response => {
                    if(response.data.error) {
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error
                        });
                    }
                    this.getdisk();
                    this.$parent.$parent.getServices();
                    this.$parent.$parent.getdisks();
                }).catch(err => {
                    if(err.data.error) {
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: err.data.error
                        });
                    }
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not connect service!"
                    });
                })
            },
            disconnect(disk, service) {
                console.log("Disconnecting " + service + ", please wait...");
                this.$http.get(this.StacketConfig.api.disks + `/${disk}/disconnect/${service}`, {headers: {"authorization": this.token}}).then(response => {
                    if(response.data.error) {
                        this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: response.data.error
                        });
                    }
                    this.getdisk();
                    this.$parent.$parent.getServices();
                    this.$parent.$parent.getdisks();
                }).catch(err => {
                    if(err.data.error) {
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: err.data.error
                        });
                    }
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not disconnect service!"
                    });
                })
            },
            togglediskEditing() {
                if(this.diskEditing == true) {
                    this.diskEditing = !this.diskEditing;
                    this.$http.post(this.StacketConfig.api.disks + `/${this.disk.disk._id}`, {name: this.disk.disk.name}, {headers: {"authorization": this.token}}).then(response => {
                        if(response.data.error) {
                            this.$notify({
                                group: "notifications",
                                type: 'error',
                                title: "API ERROR",
                                text: response.data.error
                            });
                        }
                        this.getdisk();
                        this.$parent.$parent.getServices();
                        this.$parent.$parent.getdisks();
                    }).catch(err => {
                        this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "API ERROR",
                            text: "Could not update disk!"
                        });
                    });
                } else {
                    this.diskEditing = !this.diskEditing;
                }
            }
        }
    }
</script>