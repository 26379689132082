<template>
    <b-container v-if="!this.$route.params.page3" fluid style="color: rgb(73, 80, 87); font-family: 'Roboto'; margin-top: -50px;">
        <b-row class="mt-5">
            <b-col>
                <h1 style="color: #4886FD;">Subuser Management</h1>
            </b-col>
            <b-col cols="2" style="text-align: right;">
                <b-button @click="newSub()" variant="primary">New Subuser</b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col><hr></b-col>
        </b-row>
        <b-row>
            <b-col cols="3" style="min-width: 280px;" v-for="(subuser, index) in subusers" v-bind:key="index">
                <b-card-group deck>
                    <b-card body-tag="div" header-bg-variant="white" header-tag="header" style="border-color: #eff0f5;" class="shadow-xl serviceCard">
                        <b-card-body style="margin-bottom: -15px;">
                            <b-row style="margin-bottom: 20px;">
                                <b-col>
                                    <center>
                                        <img v-bind:src="subuser.image" style="width: 50px; border-radius: 100%;" v-b-tooltip.hover v-bind:title="subuser.fullname">
                                    </center>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <center>
                                        <h6>
                                            {{ subuser.fullname }}
                                        </h6>
                                    </center>
                                </b-col>
                            </b-row>
                        </b-card-body>
                        <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -10px;">
                            <b-row>
                                <b-col>
                                    <h6 style="text-align: middle; font-size: 13px">
                                        {{ subuser.email }}
                                    </h6>
                                </b-col>
                            </b-row>
                        </b-card-footer>
                        <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -20px;">
                            <b-row>
                                <b-col style="text-align: center;" @click="remove(subuser._id)">
                                    <i v-b-tooltip.hover title="Remove subuser" class="fas fa-unlink trashIcon" style="color:lightgray;"></i>
                                </b-col>
                                <b-col style="text-align: center;" @click="editSub(subuser._id)">
                                    <i v-b-tooltip.hover title="Edit Permissions" class="fas fa-pencil-alt editIcon" style="color:lightgray;"></i>
                                </b-col>
                            </b-row>
                        </b-card-footer>
                    </b-card>
                </b-card-group>
            </b-col>
        </b-row>
    </b-container>
    <b-container v-else-if="this.$route.params.page3" fluid style="color: rgb(73, 80, 87); font-family: 'Roboto'; margin-top: -50px;">
        <b-row class="mt-5">
            <b-col>
                <h1 v-if="this.$route.params.page3 != 'new'" style="color: #4886FD;">{{ subusers.find(usr => usr._id == this.$route.params.page3).fullname }}</h1>
                <h1 v-else-if="this.$route.params.page3 == 'new'" style="color: #4886FD;">New Subuser</h1>
            </b-col>
            <b-col cols="2" style="text-align: right;">
                <b-button :to="'/dashboard/'+this.$route.params.page+'/subusers'" variant="danger">Go Back</b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col><hr></b-col>
        </b-row>
        <b-row>
            <b-col cols="5" v-b-tooltip.hover title="This can be found under account settings">
                <b-input-group size="lg" prepend="User ID:">
                    <b-form-input v-model="newUser"></b-form-input>
                </b-input-group>
            </b-col>
            <b-col></b-col>
            <b-col cols="2" style="text-align: right;">
                <b-button v-if="this.$route.params.page3 != 'new'" variant="primary" @click="addSubuser()">Update</b-button>
                <b-button v-else-if="this.$route.params.page3 == 'new'" variant="primary" @click="addSubuser()">Add</b-button>
            </b-col>
        </b-row>
        <b-form-checkbox-group id="checkbox-group-2" v-model="newPermissions" name="flavour-2">
            <b-row>
                <b-col>
                    <b-row>
                        <b-col cols="1"></b-col>
                        <b-col class="mt-4" style="min-width: 275px!important;">
                            <h2>Power</h2>
                            <div style="border: 0.5px solid #DDDEE1; background-color: #FFFFFF; border-radius: 4px; padding: 20px;">
                                <b-row>
                                    <b-col cols="3" style="min-width: 300px;">
                        
                                        <b-form-checkbox value="service.power.start">Start</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.power.stop">Stop</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.power.kill">Kill</b-form-checkbox>
                                        <br>
                                        <br>
                                        <b-form-checkbox value="service.power.activate">Activate</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.power.hibernate">Hibernate</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.power.reactivate">Reactivate</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.console.update">Update</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.console.npminstall">NPM Install</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.console.command">Execute Console Command</b-form-checkbox>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                        <b-col cols="1"></b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="1"></b-col>
                        <b-col class="mt-4" style="min-width: 275px!important;">
                            <h2>Files</h2>
                            <div style="border: 0.5px solid #DDDEE1; background-color: #FFFFFF; border-radius: 4px; padding: 20px;">
                                <b-row>
                                    <b-col cols="3" style="min-width: 300px;">
                                        <b-form-checkbox value="service.files.read">Read files</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.files.write">Write files</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.files.download">Download files to service</b-form-checkbox>
                                        <br>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                        <b-col cols="1"></b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="1"></b-col>
                        <b-col class="mt-4" style="height: 50%;">
                            <h2>FTP</h2>
                            <div style="border: 0.5px solid #DDDEE1; background-color: #FFFFFF; border-radius: 4px; padding: 20px; height: 88%; max-height: 88%;">
                                <b-row>
                                    <b-col cols="3" style="min-width: 300px;">
                                        <b-form-checkbox value="service.ftp.read">See FTP login information</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.ftp.add">Add FTP logins</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.ftp.remove">Remove FTP logins</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.ftp.edit">Edit FTP login</b-form-checkbox>
                                        <br>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                        <b-col cols="1"></b-col>
                    </b-row>
                </b-col>
                <b-col>
                    <b-row>
                        <b-col class="mt-4" style="min-width: 275px!important;">
                            <h2>Server</h2>
                            <div style="border: 0.5px solid #DDDEE1; background-color: #FFFFFF; border-radius: 4px; padding: 20px;">
                                <b-row>
                                    <b-col cols="3" style="min-width: 300px;">
                                        <b-form-checkbox value="service.usage">View Ressource Usage</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.delete">Delete Service</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.settings.version">Change Version</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.settings.package">Change Package</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.settings.name">Change Name</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.settings.autorestart">Toggle Autostart</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.settings.firmware">Change Firmware version</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.settings.environment">Edit Environment Variables</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.allocations.add">Add Allocations</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.allocations.remove">Remove Allocations</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.allocations.edit">Edit Allocations</b-form-checkbox>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                        <b-col cols="1"></b-col>
                    </b-row>
                    <b-row>
                        <b-col class="mt-4" style="height: 50%;">
                            <h2>Subusers</h2>
                            <div style="border: 0.5px solid #DDDEE1; background-color: #FFFFFF; border-radius: 4px; padding: 20px; height: 88%; max-height: 88%;">
                                <b-row>
                                    <b-col cols="3" style="min-width: 300px;">
                                        <b-form-checkbox value="service.subusers.add">Add Subusers</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.subusers.remove">Remove Subusers</b-form-checkbox>
                                        <br>
                                        <b-form-checkbox value="service.subusers.edit">Edit Subusers</b-form-checkbox>
                                        <br>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                        <b-col cols="1"></b-col>
                    </b-row>
                    <b-row>
                        <b-col class="mt-4" style="height: 50%;">
                            <h2>Danger Zone</h2>
                            <div style="border: 0.5px solid #DDDEE1; background-color: #FFFFFF; border-radius: 4px; padding: 20px; height: 88%; max-height: 88%;">
                                <b-row>
                                    <b-col style="min-width: 300px;">
                                        <b-form-checkbox value="*">Full Control</b-form-checkbox>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                        <b-col cols="1"></b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-form-checkbox-group>

    </b-container>
</template>

<style lang="scss">
    .editIcon {
        cursor: pointer;
        color: lightgray!important;
        transition: 0.15s;
    }
    .editIcon:hover {
        color: rgb(72, 134, 253)!important;
    }
</style>

<script>

export default {
    name: "Service Subusers",
    components: {
        
    },
    props: [
        "service"
    ],
    data: () => {
        return {
            token: "",
            subusers: [],
            newPermissions: [],
            newUser: "",
            state: "new"
        }
    },
    mounted() {
        this.token = localStorage.getItem("user-token");
        this.getSubusers();
    },
    methods: {
        newSub(){
            this.state = "new";
            this.$router.push('/dashboard/'+this.$route.params.page+'/subusers/new');
            this.newPermissions = [
                //Power
                "service.power.start",
                "service.power.stop",
                "service.power.kill",
                "service.power.activate",
                "service.power.hibernate",
                "service.power.reactivate",
                //Console
                "service.console.update",
                "service.console.npminstall",
                "service.console.command",
                //Files:
                "service.files.read",
                "service.files.write",
                //FTP
                "service.ftp.read",
                //Service
                "service.usage",
                "service.settings.version",
                "service.settings.environment"
            ]
        },
        editSub(id){
            this.state = "edit";
            this.$router.push('/dashboard/'+this.$route.params.page+'/subusers/' + id);
            this.newUser = id;
            this.newPermissions = this.subusers.find(usr => usr._id.toString() == id.toString()).permissions;
        },
        addSubuser(){
            this.$http.post(this.StacketConfig.api.services + "/" + this.$route.params.page + "/subusers", { user: this.newUser, permissions: this.newPermissions}, {headers: {"authorization": this.token}}).then(async response => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                if(this.state == "new"){
                    this.$notify({
                        group: "notifications",
                        type: 'success',
                        title: "Success",
                        text: "Subuser Added"
                    });
                } else if(this.state == "edit"){
                    this.$notify({
                        group: "notifications",
                        type: 'success',
                        title: "Success",
                        text: "Subuser Edited"
                    });
                }
                this.$router.push('/dashboard/'+this.$route.params.page+'/subusers');
                this.getSubusers();
                try {
                    this.$parent.$parent.getServices();
                } catch(e){}
            }).catch(err => {
                if(this.state == "new"){
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not add subuser!"
                    })
                } else if(this.state == "edit"){
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: "Could not edit subuser!"
                    })
                }
            });
        },
        remove(id){
            this.$http.delete(this.StacketConfig.api.services + "/" + this.$route.params.page + "/subusers/" + id, {headers: {"authorization": this.token}}).then(async response => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Success",
                    text: "Subuser removed"
                });
                this.getSubusers();
                try {
                    this.$parent.$parent.getServices();
                } catch(e){}
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not remove subusers!"
                })
            });
        },
        getSubusers() {
            this.$http.get(this.StacketConfig.api.services + "/" + this.$route.params.page + "/subusers", {headers: {"authorization": this.token}}).then(async response => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                this.subusers = response.data;
                console.log(this.subusers)
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not receive subusers!"
                })
            });
        }
    }
}  
</script>