<template>
    <div style="height: 100%; background-color: #f6f7fb; ">
        <b-modal id="newFolder" @ok="newFolderCreate" v-bind:title="Lang.dashboard.sidebar.modal.newFolder">
            <b-form-group
                v-bind:label="Lang.dashboard.sidebar.modal.folderName"
            >
            <b-form-input
                id="networkName"
                v-model="newFolder.name"
                required
                v-bind:placeholder="Lang.dashboard.sidebar.modal.enterName"
            />
        </b-form-group>
            <b-form-group
                label="Add Service, Network or Disk to your folder"
            >
                <b-dropdown text="Select" class="m-md-2" style="background-color: #4886FD; border-color: #4886FD; border-radius: 4px;" variant="primary">
                    <span v-for="(service, key) in services" v-bind:key="key">
                        <b-dropdown-item v-if="(newFolder.services.find(e => e.toString() == service._id.toString()) != null) == false" @click="newFolder.services.push(service._id)">
                            <b>Service - </b> {{ service.name }}
                        </b-dropdown-item>
                    </span>
                    <span v-for="(network, key) in networks" v-bind:key="key">
                        <b-dropdown-item v-if="(newFolder.networks.find(e => e.toString() == network._id.toString()) != null) == false" @click="newFolder.networks.push(network._id)">
                            <b>Network - </b> {{ network.name }}
                        </b-dropdown-item>
                    </span>
                    <span v-for="(disk, key) in disks" v-bind:key="key">
                        <b-dropdown-item v-if="(newFolder.disks.find(e => e.toString() == disk._id.toString()) != null) == false" @click="newFolder.disks.push(disk._id)">
                            <b>Disk - </b> {{ disk.name }}
                        </b-dropdown-item>
                    </span>
                </b-dropdown>
            </b-form-group>
            <b-form-group
                v-bind:label="'Added Services / Networks / Drives (' + (newFolder.services.length + newFolder.networks.length + newFolder.disks.length) + ')'"
            >
                <b-list-group>
                    <span v-for="(serviceId, key) in newFolder.services" v-bind:key="key">
                        <b-list-group-item @click="deleteNewFolderThing('services', serviceId)" class="newFolderListItem">
                            {{ services.find(s => s._id == serviceId).name }}
                            <span style="float: right; font-weight: bold;">
                                Service
                            </span>
                        </b-list-group-item>
                    </span>
                    <span v-for="(networkId, key) in newFolder.networks" v-bind:key="key">
                        <b-list-group-item @click="deleteNewFolderThing('networks', networkId)" class="newFolderListItem">
                            {{ networks.find(s => s._id == networkId).name }}
                            <span style="float: right; font-weight: bold;">
                                Network
                            </span>
                        </b-list-group-item>
                    </span>
                    <span v-for="(diskId, key) in newFolder.disks" v-bind:key="key">
                        <b-list-group-item @click="deleteNewFolderThing('disks', diskId)" class="newFolderListItem">
                            {{ disks.find(s => s._id == diskId).name }}
                            <span style="float: right; font-weight: bold;">
                                Disk
                            </span>
                        </b-list-group-item>
                    </span>
                </b-list-group>
            </b-form-group>
        </b-modal>
        <b-container fluid style="height: 100%; position: absolute; z-index: 99; width: 100%; background-color: rgba(255, 255, 255, 0.5); padding: 0; margin: 0;" v-if="service.status == 'hibernating' && this.$route.params.page && this.$route.params.page != 'starred' && this.$route.params.page != 'shared' && this.$route.params.page != 'teams' && this.$route.params.page != 'disks' && this.$route.params.page != 'support' && this.$route.params.page !='networks' && this.$route.params.page != 'new' && this.$route.params.page != 'settings' && this.$route.params.page != 'folders'">
            <div v-if="this.activating == false && this.service" style="border-radius: 4px; padding: 20px; position: absolute; left: 0; right: 0; bottom: 0; top: 0; margin: auto; height: 25%; width: 25%; text-align: center;">
                <h1>Hibernation</h1>
                <h6>This service must be activated before it is accessible.</h6>
                <b-button variant="primary" style="margin-top: 15px;" v-on:click="activateService()" v-if="this.activating == false">Activate</b-button>
                <h2 v-else style="color: #4886FD;"><i class="fas fa-spinner spinnerAnim"></i></h2>
                <router-link to="/dashboard" style="text-decoration: none;">
                    <h6 style="margin-top: 20px;"><i class="fas fa-chevron-left"></i> {{ Lang.dashboard.sidebar.myDash }}</h6>
                </router-link>
            </div>
            <div v-else-if="this.activating == true && this.service" style="border-radius: 4px; padding: 20px; position: absolute; left: 0; right: 0; bottom: 0; top: 0; margin: auto; height: 25%; width: 25%; text-align: center;">
                <h1>Activating...</h1>
                <h6>{{ ActivateMessage }}</h6>
                <h2 style="color: #4886FD;"><i class="fas fa-spinner spinnerAnim"></i></h2>
                <router-link to="/dashboard" style="text-decoration: none;">
                    <h6 style="margin-top: 20px;"><i class="fas fa-chevron-left"></i> {{ Lang.dashboard.sidebar.myDash }}</h6>
                </router-link>
            </div>
        </b-container>
        <b-container fluid style="height: 100%;">
            <b-row>
                <b-col style="padding-left: 0; padding-right: 0;">
                    <b-navbar type="light" variant="light" style="border-bottom: lightgrey solid 1px;">
                        <b-navbar-brand style="margin-left: 75px;">
                            <router-link to="/"><img src="@/assets/images/branding/brandingBlue.png" style="margin-bottom: 5px;" height=25px></router-link>
                        </b-navbar-brand>
                        <b-navbar-nav class="ml-auto">
                            <b-nav-item right style="margin-right: 25px;" v-bind:href="promotion.link">
                                {{ promotion.text }}
                            </b-nav-item>
                            <b-nav-item-dropdown right style="margin-right: 10px;">
                                <template v-slot:button-content>
                                    {{ user.fullname }}
                                </template>
                                <b-dropdown-item to="/dashboard/settings">{{ Lang.dashboard.sidebar.settings }}</b-dropdown-item>
                                <b-dropdown-item @click="logout">{{ Lang.dashboard.sidebar.logout }}</b-dropdown-item>
                            </b-nav-item-dropdown>
                            <!--<b-nav-item right>
                                <i class="fas fa-th"></i>
                            </b-nav-item>-->
                        </b-navbar-nav>
                    </b-navbar>
                </b-col>
            </b-row>
            <b-row style="padding-left: 0; padding-right: 0; height: calc(100% - 59px);">
                <b-col cols="2" style="border-right: lightgrey solid 1px; background-color: white; min-width: 190px;">
                    <div style="text-align: center!important; margin-top: 30px;">
                        <div v-if="!this.$route.params.page || this.$route.params.page == 'starred' || this.$route.params.page == 'shared' || this.$route.params.page == 'teams' || this.$route.params.page == 'disks' || this.$route.params.page == 'support' || this.$route.params.page == 'networks' || this.$route.params.page == 'nodes' || this.$route.params.page == 'settings' || this.$route.params.page == 'folders'">
                            <b-button to="/dashboard/new" variant="primary" style="margin-left: 10%; width: 70%; line-height: 43px; font-size: 17px; font-family: 'Roboto', sans-serif; font-weight: 500;">
                                <i class="fas fa-plus-circle" style="margin-right: 5px;"></i>
                                New Service
                            </b-button>
                            <i class="fas fa-folder-plus folderButton" v-b-modal.newFolder></i>
                        </div>
                        <div v-else-if="this.$route.params.page != 'new'">
                            <router-link to="/dashboard" class="backToDashboard" style="color: rgb(199, 199, 199); text-decoration: none;">
                                <h6><i class="fas fa-chevron-left"></i> {{ Lang.dashboard.sidebar.myDash }}</h6>
                            </router-link>
                            <b-form-select v-on:change="changeService" style="width: 80%; margin-top: 5px; font-size: 17px; font-family: 'Roboto', sans-serif; font-weight: 500;" :options="this.serviceOptions" v-model="selected" v-if="this.$route.params.page != 'staff'">
                                <template v-slot:first>
                                    <b-form-select-option :value="null" disabled>Select a Service</b-form-select-option>
                                </template>
                            </b-form-select>
                        </div>
                        <div v-else>
                            <router-link to="/dashboard" class="backToDashboard" style="color: rgb(199, 199, 199); text-decoration: none;">
                                <h6><i class="fas fa-chevron-left"></i> {{ Lang.dashboard.sidebar.myDash }}</h6>
                            </router-link>
                        </div>
                    </div>
                    <div id="overview" style="margin-top: 20px; width: 100%!important; font-size: 20px;" v-if="!this.$route.params.page || this.$route.params.page == 'starred' || this.$route.params.page == 'shared' || this.$route.params.page == 'teams' || this.$route.params.page == 'disks' || this.$route.params.page == 'support' || this.$route.params.page == 'networks' && this.$route.params.page != 'new' || this.$route.params.page == 'settings' || this.$route.params.page == 'nodes' || this.$route.params.page == 'folders'">
                        <router-link tag="div" style="margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard" v-if="!this.$route.params.page || this.$route.params.page == 'folders'">
                            <i class="fas fa-cube" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.myDash }}
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard" v-else="">
                            <i class="fas fa-cube" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.myDash }}
                        </router-link>


                        <!--<div style="opacity: 0.4; margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" v-if="this.$route.params.page == 'teams'">
                            <i class="fas fa-users" style="color: #4886FD; margin-right: 10px;"></i>Teams
                        </div>
                        <div class="optionNotSelected" tag="div" style="opacity: 0.4; color: #d6d6d6; margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" v-else="">
                            <i class="fas fa-users" style="color: #d6d6d6; margin-right: 10px;"></i>Teams
                        </div>!-->


                        <router-link tag="div" style="margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/networks" v-if="this.$route.params.page == 'networks'">
                            <i class="fas fa-network-wired" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.networks }}
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/networks" v-else="">
                            <i class="fas fa-network-wired" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.networks }}
                        </router-link>

                        <router-link tag="div" style="margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/nodes" v-if="this.$route.params.page == 'nodes'">
                            <i class="fas fa-th" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.nodes }}
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/nodes" v-else="">
                            <i class="fas fa-th" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.nodes }}
                        </router-link>

                        <router-link tag="div" style="margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/disks" v-if="this.$route.params.page == 'disks'">
                            <i class="far fa-hdd" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.virDisks }}
                        </router-link>
                        <router-link tag="div" class="optionNotSelected" style="color: #d6d6d6; margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/disks" v-else="">
                            <i class="far fa-hdd" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.virDisks }}
                        </router-link>

                        <!--<div style="opacity: 0.4; margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/images" v-if="this.$route.params.page == 'images'">
                            <i class="far fa-bookmark" style="color: #4886FD; margin-right: 10px;"></i>Images
                        </div>
                        <div class="optionNotSelected" style="opacity: 0.4; color: #d6d6d6; margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/images" v-else="">
                            <i class="far fa-bookmark" style="color: #d6d6d6; margin-right: 10px;"></i>Images
                        </div>-->


                        <!--<div style="margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/backups" v-if="this.$route.params.page == 'backups'">
                            <i class="far fa-file-archive" style="color: #4886FD; margin-right: 10px;"></i>Backups
                        </div>
                        <div class="optionNotSelected" style="color: #d6d6d6; margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/backups" v-else="">
                            <i class="far fa-file-archive" style="color: #d6d6d6; margin-right: 10px;"></i>Backups
                        </div>-->


                        <!--<router-link tag="div" style="margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/support" v-if="this.$route.params.page == 'support'">
                            <i class="far fa-envelope-open" style="color: #4886FD; margin-right: 10px;"></i>Support
                        </router-link>-->

                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/guides">
                            <i class="fas fa-map-signs" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.guides }}
                        </router-link>

                        <a class="optionNotSelected" tag="div" style="text-decoration: none;">
                            <div style="color: #d6d6d6; margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" href="https://discord.gg/dNVbXBK">
                                <i class="far fa-envelope-open" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.support }}
                            </div>
                        </a>

                        <router-link tag="div" style="margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/settings" v-if="this.$route.params.page == 'settings'">
                            <i class="fas fa-cog" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.settings }}
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/settings" v-else="">
                            <i class="fas fa-cog" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.settings }}
                        </router-link>

                        <span v-if="user.role == 'developer' || user.role == 'supporter'">
                            <router-link tag="div" style="margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/staff/nodes" v-if="this.$route.params.page == 'staff'">
                                <i class="fas fa-tools" style="color: #4886FD; margin-right: 10px;"></i>Staff
                            </router-link>
                            <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; margin-left: 30px; padding: 5px; cursor: pointer; font-weight: 500;" to="/dashboard/staff/nodes" v-else>
                                <i class="fas fa-tools" style="color: #d6d6d6; margin-right: 10px;"></i>Staff
                            </router-link>
                        </span>
                    </div>
                    <div id="staff" v-else-if="this.$route.params.page == 'staff'">
                        <!-- Nodes -->
                        <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/staff/nodes'" v-if="this.$route.params.page2 == 'nodes'">
                            <i class="fas fa-network-wired" style="color: #4886FD; margin-right: 10px;"></i>Nodes
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/staff/nodes'" v-else>
                            <i class="fas fa-network-wired" style="color: #d6d6d6; margin-right: 10px;"></i>Nodes
                        </router-link>

                        <!-- Services -->
                        <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/staff/services'" v-if="this.$route.params.page2 == 'services'">
                            <i class="fas fa-cube" style="color: #4886FD; margin-right: 10px;"></i>Services
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/staff/services'" v-else>
                            <i class="fas fa-cube" style="color: #d6d6d6; margin-right: 10px;"></i>Services
                        </router-link>
                    </div>
                    <div id="overview" v-else-if="this.$route.params.page != 'new' && this.$route.params.page != 'settings'" style="margin-top: 20px; width: 100%!important; font-size: 20px; font-family: Roboto;">
                        <h5 style="color: #495057;">{{ Lang.dashboard.sidebar.service.manage }}</h5>

                        <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/overview'" v-if="this.$route.params.page2 == 'overview'">
                            <i class="fas fa-cube" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.overview }}
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/overview'" v-else>
                            <i class="fas fa-cube" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.overview }}
                        </router-link>


                        <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/files'" v-if="this.$route.params.page2 == 'files' && (hasPermission('service.files.read') || hasPermission('*'))">
                            <i class="fas fa-folder" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.fileManager }}
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/files'" v-else-if="(hasPermission('service.files.read') || hasPermission('*'))">
                            <i class="fas fa-folder" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.fileManager }}
                        </router-link>


                        <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/files'" v-if="this.$route.params.page2 == 'allocations'">
                            <i class="fas fa-network-wired" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.allocations }}
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/allocations'" v-else>
                            <i class="fas fa-network-wired" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.allocations }}
                        </router-link>


                        <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/history/1'" v-if="this.$route.params.page2 == 'history'">
                            <i class="fas fa-history" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.history }}
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/history/1'" v-else>
                            <i class="fas fa-history" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.history }}
                        </router-link>


                        <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/settings'" v-if="this.$route.params.page2 == 'settings' && (hasPermission('service.settings') || hasPermission('*'))">
                            <i class="fas fa-cog" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.settings }}
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/settings'" v-else-if="hasPermission('service.settings') || hasPermission('*')">
                            <i class="fas fa-cog" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.settings }}
                        </router-link>

                        <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/subusers'" v-if="this.$route.params.page2 == 'subusers'">
                            <i class="fas fa-user-friends" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.subusers }} <b-badge style="margin-left: 10px;" variant="primary">{{ Lang.dashboard.sidebar.new }}</b-badge>
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/subusers'" v-else>
                            <i class="fas fa-user-friends" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.subusers }} <b-badge style="margin-left: 10px;" variant="primary">{{ Lang.dashboard.sidebar.new }}</b-badge>
                        </router-link>

                        <h5 style="color: #495057; margin-top: 10px;">{{ Lang.dashboard.sidebar.service.control }}</h5>

                        <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/console'" v-if="this.$route.params.page2 == 'console' && (hasPermission('service.control.console.read') || hasPermission('*') || hasPermission('service.control.console.command'))">
                            <i class="fas fa-terminal" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.console }}
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/console'" v-else-if="hasPermission('service.control.console.command') || hasPermission('*') || hasPermission('service.control.console.read')">
                            <i class="fas fa-terminal" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.console }}
                        </router-link>


                        <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/power'" v-if="this.$route.params.page2 == 'power' && (hasPermission('service.control.power') || hasPermission('*'))">
                            <i class="fas fa-power-off" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.power }}
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/power'" v-else-if="hasPermission('service.control.power') || hasPermission('*')">
                            <i class="fas fa-power-off" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.power }}
                        </router-link>


                        <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/resize'" v-if="this.$route.params.page2 == 'resize'">
                            <i class="fas fa-layer-group" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.resize }}
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/resize'" v-else>
                            <i class="fas fa-layer-group" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.resize }}
                        </router-link>


                        <router-link tag="div" style="margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/versions'" v-if="this.$route.params.page2 == 'versions'">
                            <i class="fas fa-code-branch" style="color: #4886FD; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.version }}
                        </router-link>
                        <router-link class="optionNotSelected" tag="div" style="color: #d6d6d6; margin-top: 12.5px; padding: 5px; cursor: pointer; font-weight: 500;" :to="'/dashboard/'+this.$route.params.page+'/versions'" v-else>
                            <i class="fas fa-code-branch" style="color: #d6d6d6; margin-right: 10px;"></i>{{ Lang.dashboard.sidebar.service.version }}
                        </router-link>
                    </div>
                    <div v-else-if="this.$route.params.page != 'settings'" style="margin-top: 20px; width: 100%!important; font-size: 20px; font-family: Roboto; color: rgb(73, 80, 87);">
                        <hr>
                        <h4 style="text-align: center;">Order Info</h4>
                        <hr>
                        <b-row>
                            <b-col>
                                <h5>Plan</h5>
                                <h5 class="mt-3">Service</h5>
                                <h5 class="mt-3" v-if="newService.type == 'minecraft'">Platform</h5>
                                <h5 class="mt-3">Version</h5>
                                <br>
                                <h5 class="mt-3">Location</h5>
                                <h5 class="mt-3">Ownership</h5>
                                <h5 class="mt-3">Payment Rate</h5>
                                <br>
                                <h5 class="mt-3" v-if="coupon.code != '' && coupon.package == newService.package">Coupon</h5>
                                <h5 class="mt-3">Estimated Total</h5>
                            </b-col>
                            <b-col style="font-size: 1.25rem; line-height: 1.2;">
                                <p style="text-transform: capitalize;">
                                    <span v-if="$refs.newServiceRef && $refs.newServiceRef.plan">
                                        {{ $refs.newServiceRef.plan }}
                                    </span>
                                    <span v-else>
                                        Unknown
                                    </span>
                                </p>
                                <p class="mt-3">
                                    {{ serviceNames[newService.type] }}
                                </p>
                                <p class="mt-3" style="text-transform: capitalize;" v-if="newService.type == 'minecraft'">
                                    {{ newService.platform }}
                                </p>
                                <p class="mt-3" style="text-transform: capitalize;">
                                    {{ newService.version }}
                                </p>
                                <br>
                                <p class="mt-3">
                                    {{ (newService.node == 'fsn7') ? 'Falkenstein' : (newService.node == 'fsn8') ? 'Falkenstein' : (newService.node == 'fsn9') ? 'Falkenstein' : (newService.node == 'fsn10') ? 'Falkenstein' : (newService.node == 'eu-nbg1') ? 'Nuremberg' : (newService.node == 'eu-hel1') ? 'Helsinki' : (newService.node == 'us-la1') ? 'Los Angeles' : 'Invalid' }}
                                </p>
                                <p class="mt-3">
                                    {{ (newService.ownership.type == 'personal') ? 'Personal' : (newService.ownership.type == 'team' && newService.ownership.team != null) ? teams.find(team => team._id == newService.ownership.team).name : 'Invalid' }}
                                </p>
                                <p class="mt-3" style="text-transform: capitalize;">
                                    {{ newService.paymentType }}
                                </p>
                                <br>
                                <p class="mt-3" v-if="coupon.code != '' && coupon.package == newService.package" style="text-overflow: ellipsis; overflow: hidden;" v-b-tooltip.hover v-bind:title="$route.query.coupon">
                                    {{ $route.query.coupon }}
                                </p>
                                <p class="mt-3" v-b-tooltip.hover v-bind:title="'$'+(newService.packageInfo.price / 270).toFixed(4)+' is consumed from your balance every hour while the service is activated.'">
                                    ${{ newService.packageInfo.price.toFixed(2) }}<small>/mo</small>*
                                </p>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <div style="text-align: center!important;" v-if="newService.version != 'Unknown' && newService.platform != 'Unknown'" >
                                    <b-button variant="primary" @click="postService()" class="mt-3" style="width: 80%; line-height: 43px; font-size: 17px; font-family: 'Roboto', sans-serif; font-weight: 500;">
                                        <i class="fas fa-shopping-cart" style="margin-right: 5px;"></i>
                                        Place Order
                                    </b-button>
                                </div>
                                <div style="text-align: center!important;" v-else="">
                                    <b-button variant="primary" class="mt-3" style="background-color: #4886FD!important; border-color: #4886FD!important; cursor:default; opacity: 0.7; width: 80%; line-height: 43px; font-size: 17px; font-family: 'Roboto', sans-serif; font-weight: 500;">
                                        <i class="fas fa-shopping-cart" style="margin-right: 5px;"></i>
                                        Place Order
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                    

                    <div id="overview" style="margin-top: 20px; width: 12.5%!important; position: fixed; bottom: 30px; left: 45px;">
                        <h6>Balance</h6>
                        <b-progress :value="usedBalance" :max="usedBalance + user.balance"></b-progress>
                        <p style="font-size: 12px; color: #acabab;">${{ usedBalance.toFixed(2) }} of ${{ (usedBalance + user.balance).toFixed(2) }} used this month <br><b-link :to="'/dashboard/settings#billing'">Need to fill up?</b-link></p>
                    </div>
                </b-col>
                <b-col>
                    <index v-if="!this.$route.params.page" :user="user" :folders="folders" :disks="disks" :services="services" :networks="networks"></index>
                    <shared v-else-if="this.$route.params.page == 'shared'"></shared>
                    <starred v-else-if="this.$route.params.page == 'starred'"></starred>
                    <staff v-else-if="this.$route.params.page == 'staff'"></staff>
                    <newService v-else-if="this.$route.params.page == 'new'" :teams="teams" :newService="newService" ref="newServiceRef"></newService>
                    <networks v-else-if="this.$route.params.page == 'networks'" :teams="teams" :networks="networks" :services="services"></networks>
                    <nodes v-else-if="this.$route.params.page == 'nodes'" :teams="teams" :services="services"></nodes>
                    <disks v-else-if="this.$route.params.page == 'disks'" :teams="teams" :disks="disks" :services="services"></disks>
                    <settings v-else-if="this.$route.params.page == 'settings'" :profile="user" :usedBalance="usedBalance" :disks="disks" :services="services" :lastPayment="lastPayment"></settings>
                    <folder v-else-if="this.$route.params.page == 'folders' && this.$route.params.page2" :user="user" :folder="folders.find(f => f._id == this.$route.params.page2)" :folders="folders" :disks="disks" :services="services" :networks="networks"></folder>
                    <service v-else-if="this.$route.params.page" :key="$route.params.page" ref="serviceRef"></service>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

import index from "@/pages/dashboard/index.vue";
import shared from "@/pages/dashboard/shared.vue";
import starred from "@/pages/dashboard/starred.vue";
import service from "@/pages/dashboard/service.vue";
import networks from "@/pages/dashboard/networks.vue";
import nodes from "@/pages/dashboard/nodes.vue";
import disks from "@/pages/dashboard/disks.vue";
import newService from "@/pages/dashboard/newService.vue";
import settings from "@/pages/dashboard/settings.vue";
import folder from "@/pages/dashboard/folder.vue";

import staff from "@/pages/dashboard/staff.vue";

export default {
    name: "Dashboard",
    components: {
        index,
        shared,
        starred,
        service,
        newService,
        networks,
        nodes,
        disks,
        staff,
        settings,
        folder
    },
    data: () => {
        return {
            newFolder: {
                name: "",
                services: [],
                networks: [],
                disks: []
            },
            coupon: {
                code: "",
                package: "",
                price: 0.00,
            },
            token: "",
            user: {},
            serviceOptions: [
                
            ],
            promotion: {
                text: "",
                link: "",
                date: 0
            },
            usedBalance: 0,
            selected: null,
            access: {},
            teams: [],
            service: {},
            services: [],
            disks: [],
            networks: [],
            folders: [],
            activating: false,
            ActivateMessage: "",
            serviceNames: {
                "minecraft": "Minecraft",
                "csgo": "CS:GO",
                "nodejs": "Node.js",
                "nginx": "Nginx",
                "nginx_php": "Nginx PHP",
                "vps": "VPS",
                "mysql": "MySQL",
                "terraria": "Terraria",
                "deno": "Deno",
                "python": "Python",
                "mongodb": "MongoDB",
                "factorio": "Factorio"
            },
            newService: {
                type: "minecraft",
                version: "Unknown",
                platform: "Unknown",
                node: "eu-fsn8",
                paymentType: "hourly",
                package: "pkg1",
                packageInfo: {
                    "price": 1.5,
                    "memory": "1024",
                    "disk": 10
                },
                ownership: {
                    type: "personal",
                    team: null
                },
                lastPayment: {}
            },
        }
    },
    mounted() {
        this.token = localStorage.getItem("user-token");

        if(!localStorage.getItem("user-token")){
            return this.$router.replace("/login");
        };

        if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
            //verify the token.. :)
            this.$http.post(this.StacketConfig.api.auth + "/verify", {"token": token}).then(async response => {
                if(response.body.state == "verified"){

                    //COUPON TIME
                    if(this.$route.params.page == "new" && this.$route.query.coupon){
                        this.$http.get(this.StacketConfig.api.services + "/deals/" + this.$route.query.coupon.toString().toLowerCase(), {"token": token}).then(async response => {
                            console.log(response.data)
                            if(response.data.price){
                                this.coupon.code = this.$route.query.coupon.toString();
                                this.coupon.price = response.data.price;
                                this.coupon.package = response.data.package;

                                this.newService.package = this.coupon.package.toString();
                                
                                if(response.data.platform){
                                    this.newService.platform = response.data.platform.toString()
                                }
                                if(response.data.version){
                                    this.newService.version = response.data.version.toString()
                                }
                                if(response.data.packageInfo){
                                    this.newService.packageInfo = response.data.packageInfo;
                                }
                            }

                        }).catch(e => {
                            if(e.data.error){
                                this.$notify({
                                    group: "notifications",
                                    type: 'error',
                                    title: "API ERROR",
                                    text: e.response.data.error,
                                })
                            }
                        });
                    }

                    this.getProfile();
                    this.getServices();
                    this.getFolders();
                    this.getDisks();
                    this.getPromotion();
                    this.getPayment();
                    this.getTeams();
                    this.getNetworks();
                } else {
                    localStorage.removeItem("user-token");
                    return this.$router.replace("/login");
                }
            }).catch(err => {
                console.log(err);
            });
        }

    },
    watch: {
        service: function(val) {
            let option = this.serviceOptions.find(option => option.value == val._id);
            option.text = val.name;
        }
    },
    methods: {
        deleteNewFolderThing(type, id){
            this.newFolder[type] = this.newFolder[type].filter(e => e !== id);
        },
        newFolderCreate(){
            this.$http.post(this.StacketConfig.api.profile + "/folders", this.newFolder, {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Success",
                    text: "Folder created!"
                });
                this.$router.replace('/dashboard/folders/' + response.data._id);
                this.folders.push(response.data);
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not create folder!"
                })
            });
        },
        logout(){
            localStorage.removeItem("user-token");
            this.$router.push(`/login`);
        },
        changeService(newId){
            this.$router.push(`/dashboard/${newId}${(this.$route.params.page2 ? '/' + this.$route.params.page2 : '')}`);
        },
        postService(){
            let obj = this.newService;
            delete obj.packageInfo;

            obj.payment = obj.paymentType;
            delete obj.paymentType;

            if(obj.ownership.team) obj.team = obj.ownership.team;
            delete obj.ownership;

            obj.node = obj.node.replace("eu-", "").replace("us-", "");

            obj.type = obj.type.replace("nginx_php", "nginx-php")

            if(this.coupon.code != '' && this.coupon.package == this.newService.package){
                obj.coupon = this.coupon.code.toString();
            }

            this.$http.post(this.StacketConfig.api.services + "/", obj, {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Success",
                    text: "Service created!"
                });
                this.$router.replace('/dashboard');
                this.getServices();
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not create service!"
                })
            });
        },
        getPayment() {
            this.$http.get(this.StacketConfig.api.profile + "/payments/latest", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.lastPayment = response.data;
                this.usedBalance = 0.00;
                if(response.data.services){
                    for(var srv of response.data.services){
                        this.usedBalance += srv.amount;
                    }
                }
                if(response.data.disks){
                    for(var srv of response.data.disks){
                        this.usedBalance += srv.amount;
                    }
                }
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch balance!"
                })
            });
        },
        getPromotion () {
            this.$http.get(this.StacketConfig.api.news + "/promotion", {headers: {"authorization": this.token}}).then(async (response) => {
                this.promotion = response.data;
            }).catch(err => {
                this.promotion = {
                    text: "Unable to fetch promotion",
                    link: "/",
                    date: new Date().getTime()
                }
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch promotion!"
                })
            });
        },
        service (value) {
            this.$router.replace(`/dashboard/${value}${(this.$route.params.page2 ? '/' + this.$route.params.page2 : '')}`);
        },
        hasPermission (permission){
            if(!this.access) return false; 
            if(!this.access.permissions) return false;
            return this.access.permissions.includes(permission);
        },
        getNetworks() {
            this.$http.get(this.StacketConfig.api.networks + "/", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.networks = response.data;
                for(var network in this.networks){
                    if(!this.networks[network].team){
                        if(this.networks[network].subusers && this.networks[network].subusers.length != 0){
                            var resp = await this.$http.get(this.StacketConfig.api.networks + `/${this.networks[network]._id}/subusers`, {headers: {"authorization": this.token}});
                            this.networks[network].subusers = resp.data;    
                        }
                    } else {
                        if(this.networks[network].team.groups && this.networks[network].team.groups.length != 0){
                            var resp = await this.$http.get(this.StacketConfig.api.networks + `/${this.networks[network]._id}/groups`, {headers: {"authorization": this.token}});
                            this.networks[network].team.groups = resp.data;    
                        }
                    }
                }
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch networks!"
                })
            });
        },
        getTeams() {
            this.$http.get(this.StacketConfig.api.teams + "/", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.teams = response.data;
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch teams!"
                })
            });
        },
        async fixSubuser(service){
            this.$http.get(this.StacketConfig.api.services + `/${service._id}/subusers`, {headers: {"authorization": this.token}}).then(resp => {
                console.log(resp.data)
                service.subusers = resp.data; 
            }).catch(e => {
                console.error(e);
            })
        },
        async fixGroups(service){
            this.$http.get(this.StacketConfig.api.services + `/${service._id}/groups`, {headers: {"authorization": this.token}}).then(resp => {
                console.log(resp.data)
                service.team.groups = resp.data;
            }).catch(e => {
                console.error(e);
            })
        },
        async fixUsers(service){
            console.log("REE: " + service.name)
            if(!service.team){
                if(service.subusers && service.subusers.length != 0){
                    this.fixSubuser(service);
                }
            } else {
                if(service.team.groups && service.team.groups.length != 0){
                    this.fixGroups(service);
                }
            }
        },
        getServices() {
            this.$http.get(this.StacketConfig.api.services + "/", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.services = response.data;
                for(var _service in this.services){
                    this.serviceOptions.push({value: this.services[_service]._id, text: this.services[_service].name});
                    this.fixUsers(this.services[_service]);
                }
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch services!"
                })
            });
        },
        getDisks(){
            this.$http.get(this.StacketConfig.api.disks + `/`, {headers: {"authorization": this.token}}).then(response => {
                this.disks = response.data;
            }).catch(err => {
                console.log(err);
            })
        },
        getFolders() {
            this.$http.get(this.StacketConfig.api.profile + "/folders", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.folders = response.data;
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch folders!"
                })
            });
        },
        getProfile() {
            this.$http.get(this.StacketConfig.api.profile + "/", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.user = response.data;
            });
        },
        activateService(){
            this.activating = true;
            var Vue = this;
            this.$http.post(this.StacketConfig.api.services + "/" + this.$route.params.page + "/control", {state: "activate"}, {headers: {"authorization": this.token}}).then(async response => {
                if(response.data.error){
                    this.activating = false;
                    this.ActivateMessage = response.data.error.message;
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    });
                }
                let interval = setInterval(async() => {
                    Vue.getAction(response.data._id, action => {
                        Vue.ActivateMessage = action.message;
                        if(action.status != "running"){
                            clearInterval(interval);
                            Vue.activating = false;
                            this.$refs.serviceRef.updateService();
                        }
                    });
                }, 1000);
            }).catch(err => {
                Vue.activating = false;
                Vue.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not activate service!"
                })
            });
        },
        getAction(id, callback){
            this.$http.get(this.StacketConfig.api.services + "/" + this.$route.params.page + "/actions/" + id, {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                return callback(response.data);
            }).catch(err => {
                return callback(null);
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch action!"
                })
            });
        },
    }
}
</script>

<style lang="scss">

.folderButton {
    margin-right: 5px;
    font-size: 40px;
    color: rgb(214, 214, 214);
    margin-left: 10px;
    vertical-align: middle;
    cursor: pointer;
    transition: 0.20s;
}
.folderButton:hover {
    color: #4886FD;
}

.newFolderListItem {
    cursor: pointer;
    transition: 0.20s;
}

.newFolderListItem:hover {
    background-color: #dc3545!important;
    border-color: #dc3545!important;
    color: white;
}

.dropdown-toggle {
    background-color: transparent!important;
    border-color: transparent!important;
}

*:focus:not(a){
    outline: none!important;
    box-shadow: none!important;
}

.sidebarSubpageContainer, .sidebarSubpage {
    width: 100%!important;
}

#orgDropdownContainer {
    width: 100%;
}

#orgDropdown:hover {
    background-color: rgb(19, 63, 105)!important;
}

#orgDropdown {
    background-color: transparent!important;
    border-color: transparent!important;
    font-size: 25px!important;
    color: black!important;
    width: 100%;
}

.sidebarSubpage:hover {
    background-color: rgb(19, 63, 105)!important;
    cursor: pointer;
}

.orgDropdownOption, .sidebarSubpage {
    font-size: 17px!important;
    /*color: rgba(255, 255, 255, 0.781)!important;*/
    text-decoration: none!important;
}

.orgDropdownOption:hover, .sidebarSubpage:hover {
    color: black!important;
}

.shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}
.shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}
.shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}
.shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}
.shadow-none {
    box-shadow: none;
}

.optionNotSelected:hover {
    color: rgb(176, 176, 176)!important;
    
    i {
        color: rgb(176, 176, 176)!important;
    }
}

.backToDashboard:hover {
    color: rgb(171, 171, 171)!important;

    i {
        color: rgb(171, 171, 171)!important;
    }
}

</style>