<template>
    <b-container fluid>
        <b-row style="margin-top: 50px; margin-bottom: -50px;">
            <b-col cols="2" v-for="(folder, index) of folders" v-bind:key="index" style="margin-bottom: 25px; min-width: 200px; margin-right: 25px;">
                <folderCard :folder="folder" :disks="disks" :services="services" :networks="networks"></folderCard>
            </b-col>
        </b-row>
        <b-row style="margin-top: 50px;">
            <b-col cols="3" v-for="(service, index) of servicesSorted" v-bind:key="index" style="min-width: 375px; margin-bottom: 25px;">
                <serviceCard :service="service"></serviceCard>
            </b-col>
        </b-row>
        <!--<b-row  style="margin-top: 25px;">
            <b-col>
                <p>
                    It looks like you don't have any services yet, click <router-link to="/dashboard/new">here</router-link> to get started!
                </p>
            </b-col>
        </b-row>-->
    </b-container>
</template>
<script>

</script>
<style>
    .copyLink {
        float:right;
        cursor: pointer;
        color: #c9c8c8!important;
        transition: 0.15s;
    }
    .copyLink:hover {
        color: #9c9c9c!important;
    }
    .productIcons {
        cursor: pointer;
        color: #c9c8c8!important;
        transition: 0.15s;
    }
    .productIcons:hover {
        color: #6b6b6b!important;
    }
    .serviceCard{
        transition: all .2s ease-in-out;
    }
    .serviceCard:hover {
        transform: scale(1.025);
    }
</style>
<script>
import folderCard from '@/components/folderCard.vue';
import serviceCard from '@/components/serviceCard.vue';

export default {
    name: "My Dashboard",
    components: {
        serviceCard,
        folderCard,
    },
    props: [
        "user",
        "services",
        "folders",
        "networks",
        "disks"
    ],
    data: () => {
        return {
            token: "",
            //services: []
        }
    },
    mounted() {
        this.token = localStorage.getItem("user-token");
        if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
            //verify the token.. :)
            /*this.$http.post(this.StacketConfig.api.auth + "/verify", {"token": token}).then(response => {
                if(response.body.state == "verified"){
                    this.getServices();
                } else {
                    localStorage.removeItem("user-token");
                    return this.$router.push("/login");
                }
            }).catch(err => {
                console.log(err);
            })*/
        }
    },
    computed: {
        servicesSorted() {
            if(this.user && this.user.starred){
                var starred = this.user.starred;
                var sorted = this.services.sort(function(a, b){
                    if(a._id < b._id) { return -1; }
                    if(a._id > b._id) { return 1; }
                    return 0;
                })
                function compare(a, b) {
                    if (starred.includes(a._id.toString()) == false)
                        return 1;
                    if (starred.includes(a._id.toString()) == true)
                        return -1;
                    return 0;
                }
                return sorted.sort(compare);
            } else {
                return sorted;
            }
        }
    },
    methods: {
        convertName(name) {
            var fullname = name+"";
            var split = fullname.split(" ");
            if(split.length == 1){
                return split[0].charAt(0) + split[0].charAt(1);
            } else {
                var firstName = split[0];
                var lastName = split[split.length-1];

                return firstName.charAt(0) + lastName.charAt(0);
            }

        },
        star(index, serviceId) {
            this.$http.post(this.StacketConfig.api.profile + "/star", {service: serviceId}, {headers: {"authorization": this.token}}).then((response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                if(response.data == "starred"){
                    this.$parent.user.starred.push(serviceId)
                } else if(response.data == "unstarred"){
                    for(var star in this.$parent.user.starred){
                        this.$parent.user.starred.splice(star + 1, 1);
                    }
                }
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not star/unstar service!"
                })
            });
        },

        getServices() {
            let loader = this.$loading.show({loader: "bars", color: "#4886fd"});
            this.$http.get(this.StacketConfig.api.services + "/", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    loader.hide();
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                loader.hide();
                this.services = response.data;
                for(var service in this.services){
                    if(!this.services[service].team){
                        if(this.services[service].subusers && this.services[service].subusers.length != 0){
                            var resp = await this.$http.get(this.StacketConfig.api.services + `/${this.services[service]._id}/subusers`, {headers: {"authorization": this.token}});
                            this.services[service].subusers = resp.data;    
                        }
                    } else {
                        if(this.services[service].team.groups && this.services[service].team.groups.length != 0){
                            var resp = await this.$http.get(this.StacketConfig.api.services + `/${this.services[service]._id}/groups`, {headers: {"authorization": this.token}});
                            this.services[service].team.groups = resp.data;    
                        }
                    }
                    
                }
                this.$parent.services = this.services;
            }).catch(err => {
                loader.hide();
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch services!"
                })
            });
        }
    }
}  
</script>