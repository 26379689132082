<template>
    <b-container fluid v-if="!this.$route.params.page2">
        <b-row class="mt-4">
            <b-col>
                <b-tabs>
                    <b-tab title="Private Networks" active>
                        <b-row class="mt-3">
                            <b-col>
                                <b-button variant="primary" v-b-modal.newNetwork><i class="fas fa-project-diagram"></i> New Network</b-button>

                                <b-modal id="newNetwork" title="New Network" ok-only centered @ok="createNetwork" modal-ok="Create Network">
                                    <b-form-group
                                        label="Network Name"
                                    >
                                        <b-form-input
                                        id="networkName"
                                        v-model="newNetwork.name"
                                        required
                                        placeholder="Enter name"
                                        ></b-form-input>
                                    </b-form-group>
                                    <b-form-group label="Network Type">
                                        <b-form-radio v-model="newNetwork.type" name="type-radios" value="ipv6">IPv6</b-form-radio>
                                        <b-form-radio v-model="newNetwork.type" name="type-radios" value="ipv4">IPv4</b-form-radio>
                                    </b-form-group>
                                    <b-form-group label="Ownership">
                                        <b-form-radio v-model="newNetwork.ownership" name="ownership-radios" value="personal">Personal</b-form-radio>
                                        <b-form-radio v-model="newNetwork.ownership" name="ownership-radios" value="team">Team</b-form-radio>
                                    </b-form-group>
                                    <b-form-group
                                        label="Team"
                                        v-if="newNetwork.ownership == 'team'"
                                    >
                                        <b-form-select style="" v-model="newNetwork.team" id="networkTeam">
                                            <template v-slot:first>
                                                <b-form-select-option :value="null" disabled>Select a Team</b-form-select-option>
                                            </template>
                                            <b-form-select-option v-for="team in teams" v-bind:key="team._id" :value="team._id">
                                                {{ team.name }}
                                            </b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                    
                                </b-modal>
                            </b-col>
                        </b-row>
                        <b-row style="margin-top: 20px;">
                            <b-col cols="3" v-for="(network, index) of networks" v-bind:key="index" style="min-width: 375px; margin-bottom: 25px;">
                                <networkCard :network="network" :key="index"></networkCard>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab title="Additional IPs">
                        <b-row style="margin-top: 25px;">
                            <b-col>
                                <b-alert variant="primary" show>
                                    Open a ticket if you wish to order or cancel an additional IP address.
                                </b-alert>
                            </b-col>
                        </b-row>
                        <b-row v-for="(ip, index) of additionalIPs" v-bind:key="index">
                            <b-col cols="3" v-for="(ip, index) of additionalIPs" v-bind:key="index" style="min-width: 375px; margin-bottom: 25px;">
                                <b-card-group deck class="mt-3">
                                    <b-card body-tag="div" header-bg-variant="white" header-tag="header" style="border-color: #eff0f5;" class="serviceCard shadow-xl">
                                        <template v-slot:header>
                                            <h6 class="mb-0" style="color: #777676;">
                                                <span style="color: #777676;">{{ ip.name }}</span>
                                            </h6>
                                        </template>
                                        <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                                            <b-row>
                                                <b-col style="margin-left: -15px;">
                                                    <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                                                        IP Address
                                                    </h6>
                                                </b-col>
                                                <b-col>
                                                    <h6 style="text-align: right; font-size: 13px">
                                                        {{ ip.ip }}
                                                    </h6>
                                                </b-col>
                                            </b-row>
                                        </b-card-footer>
                                        <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                                            <b-row>
                                                <b-col style="margin-left: -15px;">
                                                    <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                                                        Network Type
                                                    </h6>
                                                </b-col>
                                                <b-col>
                                                    <h6 style="text-align: right; font-size: 13px">
                                                        {{ (ip.type == "ipv4") ? "IPv4" : (ip.type == "ipv6") ? "IPv6" : "Unknown" }}
                                                    </h6>
                                                </b-col>
                                            </b-row>
                                        </b-card-footer>
                                        <b-card-footer style="background-color: transparent; color:#727272; margin-bottom: -5px;">
                                            <b-row>
                                                <b-col style="margin-left: -15px;">
                                                    <h6 style="margin-left: 10px; font-size: 13px; text-transform: capitalize;">
                                                        Stacket Node
                                                    </h6>
                                                </b-col>
                                                <b-col>
                                                    <h6 style="text-align: right; font-size: 13px; text-transform: uppercase;">
                                                        {{ ip.node }}
                                                    </h6>
                                                </b-col>
                                            </b-row>
                                        </b-card-footer>
                                    </b-card>
                                </b-card-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
            </b-col>
        </b-row>
        <!-- <span v-for="(network, index) of networks" v-bind:key="index">
            <b-row style="margin-top: 50px;">
                <b-col cols="3" style="min-width: 375px; margin-bottom: 25px;">
                    <h1>{{ network.name }}</h1>
                    <p>{{ network._id }}</p>
                </b-col>
                <b-col>
                    <b-form-select style="width: 80%; margin-top: 5px; font-size: 17px; font-family: 'Roboto', sans-serif; font-weight: 500;" v-model="connectId">
                        <template v-slot:first>
                            <b-form-select-option :value="null" disabled>Select a Service</b-form-select-option>
                        </template>
                        <b-form-select-option v-for="service in services" v-bind:key="service" :value="service._id">
                            {{ service.name }}
                        </b-form-select-option>
                    </b-form-select>
                    <b-button variant="success" @click="connect(network._id, connectId)">Connect</b-button>
                </b-col>
            </b-row>
            <b-row style="margin-top: 15px;">
                <b-col cols="3" style="min-width: 375px; margin-bottom: 25px;" v-for="(service, index) of network.services" v-bind:key="index">
                    <p>
                        <b>{{ service.name }}</b>
                        <br>
                        <b-button variant="danger" @click="disconnect(network._id, service._id)">Disconnect</b-button>
                    </p>
                    <p>
                        {{ service._id }}
                    </p>
                    <p>
                        {{ (service.networks.find(net => net.id == network._id).ipv4 ? 'IPv4' : (service.networks.find(net => net.id == network._id).ipv6) ? 'IPv6' : "Unknown") }}: {{ service.networks.find(net => net.id == network._id).ipv4 || service.networks.find(net => net.id == network._id).ipv6 }}
                    </p>
                </b-col>
            </b-row>
            <b-row style="margin-top: 20px;">
                <b-col>
                    <hr>
                </b-col>
            </b-row>
        </span> -->
    </b-container>
    <network v-else :teams="teams" :networks="networks" :services="services"></network>
</template>

<style>
    .copyLink {
        float:right;
        cursor: pointer;
        color: #c9c8c8!important;
        transition: 0.15s;
    }
    .copyLink:hover {
        color: #9c9c9c!important;
    }
    .productIcons {
        cursor: pointer;
        color: #c9c8c8!important;
        transition: 0.15s;
    }
    .productIcons:hover {
        color: #6b6b6b!important;
    }
    .serviceCard{
        transition: all .2s ease-in-out;
    }
    .serviceCard:hover {
        transform: scale(1.025);
    }
</style>

<script>
import networkCard from '@/components/networkCard.vue';
import network from '@/pages/dashboard/network.vue';

export default {
    name: "Networks",
    props: [
        "teams",
        "networks",
        "services"
    ],
    components: {
        networkCard,
        network
    },
    data: () => {
        return {
            token: "",
            connectId: "",
            additionalIPs: [],
            newNetwork: {
                name: null,
                type: 'ipv6',
                team: null,
                ownership: 'personal'
            }
        }
    },
    mounted() {
        this.token = localStorage.getItem("user-token");
        if(localStorage.getItem("user-token")){
            const token = localStorage.getItem("user-token");
            //verify the token.. :)
            this.$http.post(this.StacketConfig.api.auth + "/verify", {"token": token}).then(response => {
                if(response.body.state == "verified"){
                    //
                    this.getIPs();
                } else {
                    localStorage.removeItem("user-token");
                    return this.$router.push("/login");
                }
            }).catch(err => {
                console.log(err);
            })
        }
    },
    methods: {
        getIPs(){
            this.$http.get(this.StacketConfig.api.networks + `/additionalips`, {headers: {"authorization": this.token}}).then(response => {
                this.additionalIPs = response.data;
            }).catch(err => {
                console.log(err);
            })
        },
        connect(network, service) {
            console.log("Connecting " + service + ", please wait...");
            this.$http.get(this.StacketConfig.api.networks + `/${network}/connect/${service}`, {headers: {"authorization": this.token}}).then(response => {
                console.log(response.data);
                this.$parent.getNetworks();
            }).catch(err => {
                console.log(err);
            })
        },
        disconnect(network, service) {
            console.log("Disconnecting " + service + ", please wait...");
            this.$http.get(this.StacketConfig.api.networks + `/${network}/disconnect/${service}`, {headers: {"authorization": this.token}}).then(response => {
                console.log(response.data);
                this.$parent.getNetworks();
            }).catch(err => {
                console.log(err);
            })
        },
        createNetwork() {
            this.$http.post(this.StacketConfig.api.networks + `/`, this.newNetwork, {headers: {"authorization": this.token}}).then(response => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "SUCCESS",
                    text: "Network created!"
                });
                this.$parent.getNetworks();
                this.newNetwork = {
                    name: null,
                    type: 'ipv6',
                    team: null,
                    ownership: 'personal'
                }
            }).catch(err => {
                if(err.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: err.data.error
                    })
                }
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not create network!"
                })
            });
        }
    }
}  
</script>