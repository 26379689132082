<template>
    <div style="font-family: Roboto; color: rgb(73, 80, 87); width: 100%!important;">
        <b-row align-h="end">
            <b-col class="ml-5">
                <i class="fas fa-circle" style="color:#2ead42!important;" v-if="service.status == 'online'"></i>
                <i class="fas fa-circle" style="color:#f13862!important;" v-else-if="service.status == 'offline'"></i>
                <i class="fas fa-circle" v-else-if="service.status == 'hibernating'"></i>
                <strong class="ml-3" style="color:#2ead42!important;" v-if="service.status == 'online'">Online</strong>
                <strong class="ml-3" style="color:#f13862!important;" v-else-if="service.status == 'offline'">Offline</strong>
                <strong class="ml-3" v-else-if="service.status == 'hibernating'">Hibernating</strong>
            </b-col>
            <b-button class="mr-2" variant="success" v-on:click="$parent.startService() && setTimeout(this.getActions(), 1000)">Start</b-button>
            <b-button class="mr-2" variant="danger" v-on:click="$parent.stopService() && setTimeout(this.getActions(), 1000)">Stop</b-button>
            <b-button class="mr-5" variant="danger" v-on:click="$parent.killService() && setTimeout(this.getActions(), 1000)">Kill</b-button>
            <b-button class="mr-5" variant="primary" v-on:click="$parent.hibernateService() && setTimeout(this.getActions(), 1000)">Hibernate</b-button>
        </b-row>
        <hr>
        <b-row style="height: 90%;">
            <b-col cols="1"></b-col>
            <b-col style="height: 90%;" class="mt-4">
                <h2>History</h2>
                <div style="border: 0.5px solid #DDDEE1; background-color: #FFFFFF; border-radius: 4px; padding: 20px; height: 100%;">
                    <b-row>
                        <b-col>
                            <div v-for="item of history" v-bind:key="item" class="mb-2">
                                <b-row>
                                    <b-col>
                                        <i class="fas fa-check-circle" style="color:#2ead42!important;" v-if="item.progress == 100"></i>
                                        <i class="fas fa-spinner spinnerAnim" style="color:#f1db38!important;" v-else-if="item.status == 'running'"></i>
                                        <i class="fas fa-exclamation-circle" style="color:#f13862!important;" v-else-if="item.progress == 0 || item.status == 'error'"></i>
                                        <span class="ml-2" style="color:#2ead42!important; font-weight: 500;" v-if="item.progress > 0 && item.status == 'success' && !item.error">{{ item.message }}</span>
                                        <span class="ml-2" style="color:#f1db38!important; font-weight: 500;" v-else-if="item.status == 'running' && !item.error">{{ item.message }}</span>
                                        <span class="ml-2" style="color:#f13862!important; font-weight: 500;" v-else-if="item.progress == 0 || item.status == 'error'">{{ item.message }}</span>
                                        <!-- <b-tooltip :target="`success-${item.started}`" triggers="hover" v-if="item.progress > 0 && item.status == 'success' && !item.error">
                                            {{ item.message }}
                                        </b-tooltip> -->
                                        <!-- <b-tooltip :target="`running-${item.started}`" triggers="hover" v-else-if="item.status == 'running' && !item.error">
                                            {{ item.message }}
                                        </b-tooltip> -->
                                        <b-tooltip :target="`${item.started}`" triggers="hover" v-else-if="item.progress == 0 || item.status == 'error'">
                                            {{ item.error.message }}
                                        </b-tooltip>
                                    </b-col>
                                    <b-col cols="2">
                                        <timeago :datetime="(item.finished != 0) ? item.finished : item.started" :converterOptions="{ includeSeconds: true }" :auto-update="15"></timeago>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                    </b-row>
                    <b-pagination-nav :link-gen="linkGen" :number-of-pages="getPageNums()" use-router v-on:change="updatePagination()"></b-pagination-nav>
                </div>
            </b-col>
            <b-col cols="1"></b-col>
        </b-row>
    </div>
</template>
<script>

</script>
<style>

</style>
<script>
import paginate from 'jw-paginate';

export default {
    name: "History",
    components: {
        
    },
    data: () => {
        return {
            token: "",
            service: {},
            history: [],
            fullHistory: []
        }
    },
    mounted() {
        this.token = localStorage.getItem("user-token");
        this.service = this.$parent.service.service;
        this.access = this.$parent.service.access;
        this.products = this.$parent.products;

        this.getActions();
    },
    cron: {
        method: 'updates',
        time: 10000
    },
    methods: {
        updates(){
            if(this.$parent.service.service.status != 'hibernating'){
                this.refreshActions();
            }
        },
        linkGen(pageNum) {
            return `/dashboard/${this.$route.params.page}/history/${pageNum}`
        },
        getPageNums() {
            return paginate(this.fullHistory.length, this.$route.params.page3, 20).totalPages;
        },
        updatePagination() {
            let pagination = paginate(this.fullHistory.length, this.$route.params.page3, 20);
            this.history = this.fullHistory.slice(pagination.startIndex, pagination.endIndex);
        },
        refreshActions(){
            this.$http.get(this.StacketConfig.api.services + "/" + this.$route.params.page + "/actions", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                let pagination = paginate(response.data.length, this.$route.params.page3, 20);
                this.fullHistory = response.data;
                this.history = response.data.slice(pagination.startIndex, pagination.endIndex);
            }).catch(err => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch history!"
                })
            });
        },
        getActions(){
            let loader = this.$loading.show({loader: "bars", color: "#4886fd"});
            this.$http.get(this.StacketConfig.api.services + "/" + this.$route.params.page + "/actions", {headers: {"authorization": this.token}}).then(async (response) => {
                if(response.data.error){
                    loader.hide();
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "API ERROR",
                        text: response.data.error
                    })
                }
                let pagination = paginate(response.data.length, this.$route.params.page3, 20);
                this.fullHistory = response.data;
                this.history = response.data.slice(pagination.startIndex, pagination.endIndex);
                loader.hide();
            }).catch(err => {
                loader.hide();
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "API ERROR",
                    text: "Could not fetch history!"
                })
            });
        },
        commandName(command){
            return command == 'start_service' ? 'Start Service' : command == 'stop_service' ? 'Stop Service' : command == 'kill_service' ? 'Kill Service' : command == 'activate_service' ? 'Activate Service' : command == 'hibernate_service' ? 'Hibernate Service' : 'Unknown Command';
        }
    }
}  
</script>